import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Progress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import useThumbnail from 'hooks/useThumbnail';
import { useDrag } from 'react-dnd'

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    maxHeight: 'calc(100% - 20px)',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  caption: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 10,
  },
  cart: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 4,
    minWidth: 24,
    fontSize: 12,
    textAlign: 'center',
    border: '1px solid #989898',
  },
  addToCart: {
    position: 'absolute',
    bottom: theme.spacing(3),
  },
  placeholder: {
    position: 'relative',
    backgroundColor: '#f8f8f8',
    border: '1px solid #eee',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    height: 120,
    width: 120,
    overflow: 'hidden',
    '&.selected': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.info.light,
      boxShadow: '0 0 4px 1px rgb(0 0 0 / 15%)',
    },
    '&.cart': {
      borderColor: '#c3c3c3',
      borderWidth: 2,
    },
  },
}));

export const Placeholder = () => {
  const classes = useStyles();
  return (
    <Box className={classes.placeholder}>
      <Progress />
    </Box>
  );
};

const Thumbnail = ({ image, disableClick, onClick, selected, isInCart, onAddToCart }) => {
  const classes = useStyles();
  const thumbnail = useThumbnail(image);
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'image',
      item: { image },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        if (item && dropResult) {
          onClick(item.image, dropResult.nodeId);
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    [onClick]
  );

  const onSelect = () => onClick(image);

  const _onAddToCart = (e) => {
    e.preventDefault();
    onAddToCart(image);
  };

  return (
    <Box
      ref={dragRef}
      style={{ opacity }}
      className={cx(classes.placeholder, {
        cart: isInCart,
        selected: selected === image,
      })}
    >
      {selected === image && (
        <Button
          color="default"
          variant="contained"
          className={classes.addToCart}
          onClick={_onAddToCart}
        >
          Add to Cart
        </Button>
      )}
      {isInCart ? (
        <Tooltip title={`in cart ${isInCart} times`}>
          <span className={classes.cart}>{isInCart}</span>
        </Tooltip>
      ) : null}
      <Tooltip title={image.name}>
        <img
          onClick={disableClick ? noop : onSelect}
          src={thumbnail}
          className={classes.img}
          draggable="false"
        />
      </Tooltip>
      <Typography variant="caption" align="center" className={classes.caption}>
        {image.name}
      </Typography>
    </Box>
  );
};

export default Thumbnail;
