import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },

  margin: {
    marginBottom: '8px',
  },
  label: {
    display: 'block',
    position: 'initial',
    transform: 'scale(1)',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  hint: {
    display: 'block',
    marginBottom: theme.spacing(1),
    fontSize: 13,
  },
});

export const BootstrapInput = withStyles((theme) => ({
  root: {},
  input: {
    borderRadius: 0,
    position: 'relative',
    border: '1px solid #e0e0e0',
    padding: '12px 16px',
    color: theme.palette.text.primary,
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

class CustomInput extends PureComponent {
  render() {
    const {
      hasError,
      label,
      hint,
      classes,
      fullWidth,
      helpText,
      disableMargin,
      margin = 'dense',
      ...otherProps
    } = this.props;

    return (
      <FormControl margin={margin} fullWidth={fullWidth}>
        {label && (
          <InputLabel disableAnimation shrink className={classes.label}>
            {label}
          </InputLabel>
        )}
        {hint && (
          <Typography
            className={classes.hint}
            color="textSecondary"
            variant="caption"
          >
            {hint}
          </Typography>
        )}
        <BootstrapInput error={hasError} {...otherProps} />
        {hasError && <FormHelperText error>{helpText}</FormHelperText>}
      </FormControl>
    );
  }
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  dropdownId: PropTypes.string,
  hasError: PropTypes.bool,
  helpText: PropTypes.string,
  hint: PropTypes.string,
};

export default withStyles(styles)(CustomInput);
