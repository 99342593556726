import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from 'components/Button';

import { TYPES } from 'context/alert';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'visible',
  },
}));

const AlertComponent = ({ options, close }) => {
  const [val, setVal] = useState(options.inputValue);
  const classes = useStyles();

  const onOK = () => {
    if (options.onConfirm) {
      const result = options.onConfirm(val);
      if (result && result.then) {
        return result.then(close);
      } else {
        close();
      }
    } else {
      close();
    }
  };

  const onCancel = () => {
    if (options.onCancel) {
      options.onCancel();
    }
    close();
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      scroll="body"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>{options.title}</DialogTitle>
      <DialogContent className={classes.paper}>
        {options.description && (
          <DialogContentText>{options.description}</DialogContentText>
        )}
        {options.type === TYPES.PROMPT ? (
          options.renderInput ? (
            options.renderInput(val, setVal)
          ) : (
            <TextField
              fullWidth
              autoFocus
              value={val}
              onChange={(e) => setVal(e.target.value)}
            />
          )
        ) : null}
      </DialogContent>
      <DialogActions>
        {options.actions ? (
          options.actions.map((action, index) => (
            <Button
              onClick={() => {
                action.onClick();
              }}
              key={index}
            >
              {action.label}
            </Button>
          ))
        ) : (
          <>
            {options.showCancelButton !== false && (
              <Button onClick={onCancel} variant="text">
                {options.cancellationText}
              </Button>
            )}
            <Button
              onClick={onOK}
              disabled={TYPES.PROMPT === options.type && !val}
            >
              {options.confirmationText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertComponent;
