import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Spin from './Spin';
import usePromise from 'hooks/usePromise';

const CustomButton = ({ loading, onClick, ...rest }) => {
  const [handler, isLoading] = usePromise(onClick, loading);
  const spinStyle = rest.fullWidth ? null : { display: 'inline-block' };

  return (
    <Spin spinning={isLoading} size={10} style={spinStyle}>
      <Button disabled={isLoading} onClick={handler} {...rest}></Button>
    </Spin>
  );
};

CustomButton.propTypes = {
  loading: PropTypes.bool,
};

export default styled(CustomButton)`
  position: relative;
  button {
    margin: 8px;
  }
  .icon-left {
    margin-right: 8px;
  }
  .icon-right {
    margin-left: 8px;
  }
  .progress {
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`;
