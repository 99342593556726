import { combineReducers } from 'redux';
import cart from './cart';
import lab from './lab';
import catalogs from './catalogs';
import packages from './packages';
import app from './app';

const rootReducer = combineReducers({
  cart,
  lab,
  catalogs,
  packages,
  app,
});

export default rootReducer;