import { useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from 'components/form/Checkbox';
import ColorInput from './ColorInput';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 80,
    marginLeft: 12,
  },
}));

const ImageNode = ({ node, onChange, onImageSelect, index, onApplyToAll }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onColorChange = (val) => {
    node.borderColor = val;
    onChange(node);
  };

  const onWidthChange = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      node.borderWidth = Number(value);
      onChange(node);
    }
  };

  const onCheckboxChange = (e) => {
    const { checked, name } = e.target;
    if (name === 'grayscale') {
      Sentry.captureMessage(`Change to B&W checked - ${checked}`);
    }
    node[name] = checked;
    onChange(node);
  };

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      onImageSelect(file, node.id);
    }
  };

  const onApplyStroke = () => {
    handleMenuClose();
    onApplyToAll({
      borderColor: node.borderColor,
      borderWidth: node.borderWidth,
    });
  };

  // Hide image settings if ignoreBorderRendering is true
  // but this hides bw and other options too.
  if (node.ignoreBorderRendering) return null;

  return (
    <Box border={1} padding={1} marginBottom={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography>Image {index + 1}</Typography>
        <IconButton size="small" onClick={handleMenuOpen}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={onApplyStroke}>
            Apply stroke to all image nodes
          </MenuItem>
        </Menu>
      </Box>
      <Checkbox
        labelStyle={{ fontWeight: 400, fontSize: 14 }}
        label="Shrink to Fit (may add borders)"
        name="fitImage"
        title="This will shrink your image down so that the entire image fits in the box, with borders on the sides. Border color will be the background color."
        color="primary"
        checked={node.fitImage || false}
        onChange={onCheckboxChange}
      />
      <Checkbox
        labelStyle={{ fontWeight: 400, fontSize: 14 }}
        name="grayscale"
        label="Change to B&W"
        color="primary"
        checked={node.grayscale || false}
        onChange={onCheckboxChange}
      />
      <Box
        marginRight={2}
        marginTop={2}
        marginBottom={1}
        display="flex"
        alignItems="center"
        flex={1}
      >
        <Typography variant="body2" style={{ marginRight: 6 }}>
          Stroke Color:{' '}
        </Typography>
        <ColorInput value={node.borderColor} onChange={onColorChange} />
        <TextField
          disabled={!node.borderColor}
          className={classes.input}
          type="number"
          title="Image stroke thickness in pixels"
          size="small"
          min={0}
          max={300}
          onChange={onWidthChange}
          value={node.borderWidth}
          variant="outlined"
        />
      </Box>
      <Typography variant="caption">
        The stroke is directly around the image and covers up part of the image.
        If used as a faux border use a minimum of 100 px to be safe.
      </Typography>
    </Box>
  );
};

const ImageSettings = ({ nodes, onChange, onImageSelect, onUpdateNodes }) => {
  const onApplyToAll = (data) => {
    nodes.forEach((n) => {
      Object.assign(n, data);
    });
    onUpdateNodes(nodes);
  };

  return (
    <Box marginBottom={2}>
      {nodes.map((node, i) => (
        <ImageNode
          key={i}
          node={node}
          onChange={onChange}
          index={i}
          onImageSelect={onImageSelect}
          onApplyToAll={onApplyToAll}
        />
      ))}
    </Box>
  );
};

export default ImageSettings;
