import { useState } from 'react';
import cx from 'classnames';
import { SketchPicker } from 'react-color';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  swatch: {
    backgroundColor: (props) => props.color,
    borderRadius: 4,
    border: '1px solid #ccc',
    display: 'inline-block',
    cursor: 'pointer',
    width: 28,
    height: 24,
  },
  emptySwatch: {
    backgroundImage: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAJUlEQVR42mP4TyT48OEDUZhh1MBRA4elgcQqJBaMGjhq4LA0EACSrBbzgTx3mgAAAABJRU5ErkJggg==")`,
    backgroundRepeat: 'repeat',
    backgroundSize: '30%',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
  },
}));

const ColorInput = ({ value, onChange, enableAlpha }) => {
  const classes = useStyles({ color: value });
  const [show, setShow] = useState(false);
  const handleChangeComplete = (color) => {
    onChange(color.hex);
  };

  const toggle = () => setShow(!show);

  return (
    <>
      <Box
        className={cx(classes.swatch, !value && classes.emptySwatch)}
        onClick={toggle}
      ></Box>
      {show && (
        <Box className={classes.popover}>
          <Box className={classes.cover} onClick={toggle} />
          <SketchPicker
            disableAlpha={!enableAlpha}
            color={value}
            presetColors={[
              { color: '#fff', title: 'White' },
              { color: '#000', title: 'Black' },
              { color: '#909090', title: 'Gray' },
              { color: '#EBEBEB', title: 'Light Gray' },
              { color: '#dc3a38', title: 'Red 1' },
              { color: '#ff0300', title: 'Red 2' },
              { color: '#ff4ac6', title: 'Pink' },
              { color: '#b14bf8', title: 'Purple' },
              { color: '#0d63f2', title: 'Blue' },
              { color: '#788efd', title: 'Light Blue' },
              { color: '#21a0f9', title: 'Teal' },
              { color: '#2cebdb', title: 'Mint' },
              { color: '#0b950b', title: 'Green' },
              { color: '#fafd0f', title: 'Yellow' },
              { color: '#e54a1a', title: 'Orange' },
              { color: '#8e603a', title: 'Brown' },
            ]}
            onChange={handleChangeComplete}
          />
        </Box>
      )}
    </>
  );
};

export default ColorInput;
