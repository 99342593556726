import React, { useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { removePackage } from 'store/actions/packages';
import { useDispatch } from 'react-redux';
import PackageCard from './PackageCard';
import CreatePackage from './CreatePackage';

const useStyles = makeStyles({
  file: {
    visibility: 'hidden',
  },
});

const PackagesList = ({ packages, catalog, onFilesSelect }) => {
  const classes = useStyles();
  const fileRef = useRef();
  const [selectedPackage, setSelectedPackage] = useState();
  const [editing, setEditing] = useState(null);
  const dispatch = useDispatch();
  const sortedPackages = packages.sort((a, b) => {
    const nameA = a.packageName.toUpperCase(); // ignore upper and lowercase
    const nameB = b.packageName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const handleFileSelect = (e) => {
    const { files } = e.target;
    if (!files.length) return;
    const selectedFiles = Array.from(files);
    onFilesSelect(selectedFiles, selectedPackage, catalog);
  };

  const onPackageSelect = (pkg) => {
    setSelectedPackage(pkg);
    fileRef.current.click();
  };

  const onDeletePackage = (pkg) => {
    dispatch(removePackage(pkg.id));
  };

  const onEditPackage = (pkg) => {
    setEditing(pkg);
  };

  return (
    <Box>
      <Typography gutterBottom variant="h2">
        Packages
      </Typography>
      <Grid container spacing={2}>
        {sortedPackages.map((pkg) => (
          <Grid item sm={4} md={3} lg={3} key={pkg.id}>
            <PackageCard
              key={pkg.id}
              onPackageSelect={onPackageSelect}
              onFilesSelect={onFilesSelect}
              onDelete={onDeletePackage}
              onEdit={onEditPackage}
              pkg={pkg}
              catalog={catalog}
            />
          </Grid>
        ))}
      </Grid>
      <input
        multiple
        value=""
        onChange={handleFileSelect}
        ref={fileRef}
        id="pkgFileInput"
        type="file"
        className={classes.file}
      />
      {editing && (
        <CreatePackage
          pkg={editing}
          catalog={catalog}
          onClose={() => setEditing(null)}
          products={catalog.products}
        />
      )}
    </Box>
  );
};

export default PackagesList;
