import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const WHITE_LIST_ACTIONS = [
  'ADD_TO_CART',
  'REMOVE_FROM_CART',
  'UPLOAD',
  'UPDATE_ITEM',
  'UPDATE_CART',
  'CLEAR_CART',
  'RESET',
];

const localStoreMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (global.IS_SERVER) return result;
  if (WHITE_LIST_ACTIONS.includes(action.type)) {
    const cart = store.getState().cart;
    localStorage.setItem(
      'reduxState',
      JSON.stringify({ cart, lastUpdated: new Date().toJSON() })
    );
  }
  return result;
};

const configureStore = (preloadedState) => {
  const composeEnhancers = global.IS_SERVER
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk, localStoreMiddleware))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
