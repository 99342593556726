import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import QuantityInput from 'components/QuantityInput';
import Hidden from '@material-ui/core/Hidden';

const CartOptions = ({
  onAdd,
  quantity,
  onChange,
  editing,
  hasItems,
  hasImage,
  emptyNodesOk,
  category,
  onToggleSettings,
}) => {
  const allowCheckout = hasImage || emptyNodesOk;
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={1}
        mb={2}
      >
        <Hidden smDown>
          {/* hide view cart on mobile using another button below */}
          <Box mr={2}>
            <Button
              disabled={!hasItems}
              component={Link}
              to="/cart"
              variant="outlined"
              color="secondary"
            >
              View Cart
            </Button>
          </Box>
        </Hidden>
        <Box marginX={2}>
          <QuantityInput value={quantity} onChange={onChange} />
        </Box>
        <Button
          disabled={!quantity || !allowCheckout}
          variant="contained"
          color="secondary"
          onClick={onAdd}
        >
          {editing ? 'Update Cart' : 'Add to Cart'}
        </Button>
      </Box>
      {editing ? (
        ''
      ) : (
        <Hidden mdUp>
          <Box my={2}>
            <ButtonGroup variant="outlined">
              <Button onClick={onToggleSettings}>Settings</Button>
              <Button to={`/choose-product/${category}`} component={Link}>
                Change Size
              </Button>
              <Button
                disabled={!hasItems}
                component={Link}
                color="secondary"
                to="/cart"
              >
                View Cart
              </Button>
            </ButtonGroup>
          </Box>
        </Hidden>
      )}
    </>
  );
};

export default CartOptions;
