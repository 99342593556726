import React from 'react';
import Select from '../Select';
import { getIn } from 'formik';

const FormikReactSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  name,
  ...props
}) => {
  const onChange = (value) => {
    setFieldValue(field.name, value);
  };

  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return <Select
    value={field.value}
    {...props}
    onChange={onChange}
    helpText={fieldError}
    hasError={showError}
  />;
};

export default FormikReactSelect;
