import React, { useEffect } from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import configureStore from './store/configureStore';
import App from './App';
import makeTheme from './theme';
import 'index.css';

const store = configureStore(window.PRELOADED_STATE);

const BaseApp = ({ children }) => {

  useEffect(() => {
    const packages = localStorage.getItem('packages');
    if (packages) {
      store.dispatch({
        type: 'SET_PACKAGES',
        payload: JSON.parse(packages),
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider
          theme={makeTheme(window.PRELOADED_STATE.lab.customization)}
        >
          {children}
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

function render(Component) {
  hydrate(
    <BaseApp>
      <Component />
    </BaseApp>,
    document.getElementById('root'),
    () => {
      // [ReHydratation](https://github.com/cssinjs/jss/blob/master/docs/ssr.md)
      const jssStyles = document.getElementById('jss-ssr');
      if (jssStyles && jssStyles.parentNode)
        jssStyles.parentNode.removeChild(jssStyles);
    }
  );
}

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default;
    render(NewApp);
  });
}
