import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import Button from 'components/Button';
import { getFileHash } from 'utils';
import { restoreCart, uploadItem } from 'store/actions/cart';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';
import { Hidden } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button as MuiButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const RestoreCart = ({}) => {
  const [show, setShow] = useState(false);
  const [cart, setCart] = useState(null);
  const [restoredItems, setRestoredItems] = useState([]);
  const currentCart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const snack = useSnackbar();

  useEffect(() => {
    const localState = localStorage.getItem('reduxState');
    if (localState) {
      const state = JSON.parse(localState);
      if (state.cart?.items.length) {
        const { lastUpdated } = state;
        if (lastUpdated) {
          const now = new Date();
          const diff = now - new Date(lastUpdated);
          if (diff > 1000 * 60 * 60 * 24 * 7) {
            localStorage.removeItem('reduxState');
            return;
          }
        }
        setCart(state.cart);
        setShow(true);
      }
    }
  }, []);
  const handleFileSelect = (e) => {
    const { files } = e.target;
    Sentry.captureMessage('Cart lost - selecting files to restore');
    if (!files.length) return;
    const selectedFiles = Array.from(files).map((f) => ({
      name: f.name,
      file: f,
      hash: getFileHash(f),
    }));
    const restoredFiles = [];
    cart.items.forEach((item) => {
      if (currentCart.items.some((i) => i.id === item.id)) return;
      let found = false;
      item.product.template.nodes.forEach((node) => {
        if (node.fileHash) {
          const file = selectedFiles.find((f) => f.hash === node.fileHash);
          if (file) {
            node.image = file.file;
            found = true;
          }
          // check if the restoredItems array contains the node.fileHash
          if (
            restoredItems.some((item) =>
              item.product.template.nodes.some(
                (node) => node.fileHash === file.hash
              )
            )
          ) {
            found = false;
          }
        }
      });
      if (found) restoredFiles.push(item);
    });
    setRestoredItems((prev) => [...prev, ...restoredFiles]);
  };

  const onRestore = () => {
    dispatch(
      restoreCart({
        ...cart,
        items: restoredItems,
      })
    );
    const hashes = {};
    restoredItems.forEach((item) => {
      item.product.template.nodes.forEach((node) => {
        if (node.fileHash) {
          const hash = cart.sessionId + node.fileHash;
          hashes[hash] = node.image;
        }
      });
    });
    const keys = Object.keys(hashes);
    keys.forEach((hash) => {
      const upload = cart.uploads[hash];
      if (!upload?.key) {
        dispatch(uploadItem(hashes[hash], true));
      }
    });
    snack.enqueueSnackbar('Cart restored', { variant: 'success' });
    Sentry.captureMessage('Cart restored');
    onClose();
  };

  const onClose = () => {
    setShow(false);
    setCart();
    setRestoredItems([]);
    localStorage.removeItem('reduxState');
  };

  const StyledFileInput = styled('input')({
    display: 'none',
  });

  const StyledButton = styled(MuiButton)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    fontWeight: 'bold',
    padding: theme.spacing(1, 3),
    fontSize: '1rem',
  }));

  const handleButtonClick = () => {
    document.getElementById('folderInput').click();
  };

  if (!show) return null;
  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle>Restore Shopping Cart Items?</DialogTitle>
      <DialogContent dividers>
        <Hidden mdUp>
          <Alert severity="info" variant="filled" elevation={6}>
            Unfortunately on mobile devices we can't recover the cart easily -
            on an iPhone you can choose one image at a time, on android it
            doesn't work at all. We recommend you click Cancel at the bottom and
            start over.
            <br />
            We're sorry for the inconvenience!
          </Alert>
        </Hidden>
        <Typography paragraph>
          It looks like your cart had items in it, but something may have went
          wrong. We can try to rebuild your cart below by finding all the images
          you had in your cart.
        </Typography>
        <Typography paragraph>
          Please choose the folder where your images were loaded from. If they
          were in multiple folders try selecting the parent folder.
        </Typography>
        <Typography paragraph>
          After you choose the folder (not files, the folder!) a pop up will
          open asking if you want to 'upload' all of the images in that folder.
          Please click 'Upload'. Your files will not be uploaded, just checked
          to see if they match the files needed for the order. It should not
          take long.
        </Typography>
        <Typography paragraph>
          <a href="https://www.youtube.com/watch?v=dWzPJvGNh-4" target="_blank">
            Video on how to restore your cart (opens in a new tab).
          </a>
        </Typography>
        <Typography paragraph>
          If you click Cancel below your cart will be lost permanently.
        </Typography>
        <Typography paragraph>
          Click the Choose Files button to select the folder where your images
          were loaded from. It probably wll be already selected, then on the
          bottom right click 'Upload'. You will be selecting a folder, not
          files. You can do this multiple times if needed.
        </Typography>
        <StyledFileInput
          multiple
          value=""
          directory=""
          webkitdirectory=""
          onChange={handleFileSelect}
          id="folderInput"
          type="file"
          disabled={restoredItems.length === cart.items.length}
        />
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={handleButtonClick}
          disabled={restoredItems.length === cart.items.length}
        >
          Choose Files
        </StyledButton>
        <Typography paragraph>
          {restoredItems.length} of {cart.items.length} Files Located.
        </Typography>
        <Box mt={1}>
          <table>
            <thead>
              <tr>
                <th>Qty</th>
                <th>Product</th>
                <th>Filename (node A)</th>
              </tr>
            </thead>
            <tbody>
              {cart.items.map((item) => (
                <tr key={item.id}>
                  <td>{item.quantity}</td>
                  <td>{item.product.size}</td>
                  <td>{item.product.template.nodes[0].filename}</td>
                  <td>
                    {restoredItems.some((i) => i.id === item.id) && (
                      <CheckIcon color="success" />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!restoredItems.length}
          variant="contained"
          color="primary"
          onClick={onRestore}
        >
          {restoredItems.length === cart.items.length
            ? 'Restore All Items'
            : `Restore ${restoredItems.length} of ${cart.items.length} Items`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreCart;
