export default [
  { label: 'Open Sans', value: 'Open Sans' },
  { label: 'Kanit', value: 'Kanit' },
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Share', value: 'Share' },
  { label: 'Zilla Slab', value: 'Zilla Slab' },
  { label: 'Times New Roman', value: 'Times New Roman' },
  {
    label: 'Special Elite (distressed)',
    value: 'Special Elite',
    bold: false,
    italic: false,
  },
  { label: 'Cutive Mono', value: 'Cutive Mono', bold: false, italic: false },
  { label: 'Raleway', value: 'Raleway', bold: false, italic: false },
  { label: 'WindSong', value: 'WindSong', bold: false, italic: false },
  { label: 'Great Vibes', value: 'Great Vibes', bold: false, italic: false },
  {
    label: 'Shadows Into Light',
    value: 'Shadows Into Light',
    bold: false,
    italic: false,
  },
  {
    label: 'Caveat',
    value: 'Caveat',
    italic: false,
  },
  { label: 'Patrick Hand', value: 'Patrick Hand', bold: false, italic: false },
  { label: 'Audiowide', value: 'Audiowide', bold: false, italic: false },
  { label: 'Rampart One', value: 'Rampart One', bold: false, italic: false },
  { label: 'Faster One', value: 'Faster One', bold: false, italic: false },
];
