import { useState, useEffect } from 'react';
import noop from 'lodash/noop';
import { getFromCache, setInCache } from 'utils/img-cache';
import { getFileHash } from 'utils';
import fileIcon from 'images/download.png';

let g;

const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(objectUrl);
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = (error) => {
      URL.revokeObjectURL(objectUrl);
      reject(error);
    };
    img.src = objectUrl;
  });
};

export async function genThumb(file, options) {
  const { skipResolution, maxSize } = options;
  try {
    if (skipResolution) {
      const { width, height } = await getImageDimensions(file);
      if (width > skipResolution || height > skipResolution) {
        return;
      }
    }
    const img = document.createElement('img');
    if (!g) {
      const fineUpload = require('fine-uploader');
      g = new fineUpload.ImageGenerator(noop);
    }
    const r = await g.generate(file, img, { maxSize: maxSize ?? 100 });
    return r.src;
  } catch (error) {
    console.error('Error generating thumbnail:', error);
    return null;
  }
}

export default function useThumbnail(file, showBatchThumbnail, options = {}) {
  const [thumb, setThumb] = useState(fileIcon);

  useEffect(() => {
    (async function () {
      if (!file) return;
      const id = getFileHash(file);
      const cached = getFromCache(id);
      if (cached) {
        setThumb(cached);
      } else {
        if (!showBatchThumbnail) return;
        try {
          const src = await genThumb(file, options);
          if (src) {
            setInCache(id, src);
            setThumb(src);
          }
        } catch (error) {
          console.error('Error in useThumbnail:', error);
          // Optionally set a fallback thumbnail
          setThumb(fileIcon);
        }
      }
    })();
  }, [file, showBatchThumbnail, options]);

  return thumb;
}
