import * as React from 'react';
import { getIn } from 'formik';
import Input from '../Input';

export function fieldToTextField({
  disabled,
  field,
  form: { isSubmitting, touched, errors },
  ...props
}) {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    ...props,
    ...field,
    hasError: showError,
    helpText: showError ? fieldError : props.helpText,
    disabled: disabled ?? isSubmitting,
    variant: props.variant,
  };
}

export default function TextField({ children, ...props }) {
  return <Input {...fieldToTextField(props)}>{children}</Input>;
}
