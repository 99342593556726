import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Helmet } from 'react-helmet';
import CatalogCard from './CatalogCard';
import { Alert, AlertTitle } from '@material-ui/lab';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { Link } from 'react-router-dom';

const devEnvironment = process.env.RAZZLE_APP_STATUS;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    gap: 14,
  },
  item: {
    textAlign: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      border: '1px solid #a7a7a7',
    },
  },
  content: {
    padding: 12,
  },
  info: {
    backgroundColor: '#f9f9f9',
    padding: '1rem',
  },
  cat_title: {
    fontSize: '1.1rem',
  },
  buttons: {
    height: 40,
    marginBottom: 8,
  },
  btn: {
    margin: 4,
  },
  video: {
    marginBottom: '12px',
  },
  saleLink: {
    color: 'green',
  },
  saleAlert: {
    borderRadius: '4px',
    border: '1px solid #afdfa9',
    marginTop: 12,
  },
}));

const ClassicHome = () => {
  const classes = useStyles();
  const catalogs = useSelector((s) => s.catalogs);
  const catalogsToShow =
    devEnvironment === 'testing'
      ? catalogs
      : catalogs.filter((catalog) => !catalog.hidden);
  const lab = useSelector((s) => s.lab);
  return (
    <Box marginTop={2} marginBottom={3}>
      <Helmet title={`${lab.lab_name} - ${lab.customization.header}`}>
        <meta
          name="description"
          content={`${lab.lab_name} - ${lab.customization.description}`}
        />
      </Helmet>
      <Container fixed>
        <Box marginBottom={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" align="center">
                {lab.customization.header}
              </Typography>
              <Typography gutterBottom color="textPrimary" align="center">
                {lab.payment_options
                  ? lab.customization.description
                  : `There seems to be a problem, please contact us at ${lab.lab_phone} - you may be using the wrong link to the ordering system. `}
              </Typography>
              {lab.customization.saleTitle && (
                <Alert
                  icon={<LoyaltyIcon fontSize="inherit" />}
                  severity="success"
                  className={classes.saleAlert}
                >
                  <AlertTitle>
                    {lab.customization.saleLink ? (
                      <Link
                        to={lab.customization.saleLink}
                        className={classes.saleLink}
                      >
                        {lab.customization.saleTitle}
                      </Link>
                    ) : (
                      lab.customization.saleTitle
                    )}
                  </AlertTitle>

                  <Typography>{lab.customization?.saleDescription}</Typography>
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          className={classes.cardContainer}
        >
          {lab.payment_options &&
            catalogsToShow.map((cat) => {
              return <CatalogCard cat={cat} key={cat.cat_id} />;
            })}
        </Box>
        {(lab.customization.show_ordering_tips ||
          lab.customization.additional_tips_title) && (
          <Box mt={8} className={classes.info}>
            {lab.customization.show_ordering_tips && (
              <>
                <Typography variant="h4">
                  Order Now or Batch Order - Which Should I Use?
                </Typography>
                <Typography className={classes.video}>
                  <a href="https://youtu.be/nR4yzjKCCnc" target="_blank">
                    ▶ Watch a 5 minute video on the different ways to order
                  </a>
                </Typography>
                <Typography>
                  The <i>Order Now</i> button will take you to the normal way of
                  ordering where you can crop your images and add them to the
                  cart one at a time. Jpeg and PNG files only.
                </Typography>
                <Typography paragraph>
                  The <i>Batch Order</i> button will allow you to add a large
                  number of images directly into the cart - bypassing the crop
                  screen. You should use this if you have a lot of images,
                  especially if you have already cropped them to the size you
                  are ordering. If your images are not cropped correctly they
                  will be center cropped when we print them. Additional file
                  types allowed, may vary by category.
                </Typography>
                <Typography paragraph>
                  You can also use the <i>Batch Order</i> to add to the cart and
                  then efficiently crop each image by clicking on the
                  edit/pencil icon in the cart for the first image and then
                  using the right and left arrows while in the Editor screen to
                  scroll thru the cart items and adjust the cropping. This is
                  the most efficient way to order a large number of images that
                  need to be cropped.
                </Typography>
                <Typography paragraph>
                  Both options are not always available, for example products
                  with text are not available with the Batch workflow.
                </Typography>
              </>
            )}
            {lab.customization.additional_tips_title && (
              <>
                <Typography variant="h4">
                  {lab.customization.additional_tips_title}
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: lab.customization.additional_tips_body,
                  }}
                ></Typography>
              </>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ClassicHome;
