import { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Shipping from './components/Shipping';
import Address from './components/Address';
import Upload from './components/Upload';
import Payment from './components/Payment';

const useStyles = makeStyles((theme) => ({}));

const Checkout = ({ history }) => {
  const items = useSelector((s) => s.cart.items);
  const lab = useSelector((s) => s.lab);

  useEffect(() => {
    if (!items.length) {
      history.push('/');
    }
  }, [items]);

  return (
    <Box>
      <Helmet title={`${lab.lab_name} - Checkout`} />
      <Switch>
        <Route path="/checkout/shipping" component={Shipping} />
        <Route path="/checkout/address" component={Address} />
        <Route path="/checkout/pay" component={Payment} />
        <Route path="/checkout/upload" component={Upload} />
        <Redirect from="/checkout" to="/checkout/shipping" />
      </Switch>
    </Box>
  );
};

export default Checkout;
