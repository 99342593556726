import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from './commonTextFields';

export default class CreditCardDetail extends React.Component {
  state = {
    creditCardNumberComplete: false,
    expirationDateComplete: false,
    cvcComplete: false,
    cardNameError: false,
    cardNumberError: false,
    expiredError: false,
    cvcError: false,
  };

  onElementChange = (field, errorField) => ({
    complete,
    error = { message: null },
  }) => {
    const expectedState = { ...this.state };
    expectedState[field] = complete;
    this.setState({ [field]: complete, [errorField]: error.message });
    this.props.setFormComplete(
      expectedState.creditCardNumberComplete &&
        expectedState.cvcComplete &&
        expectedState.expirationDateComplete
    );
  };

  render() {
    const { cardNumberError, expiredError, cvcError } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <StripeTextFieldNumber
            error={Boolean(cardNumberError)}
            labelErrorMessage={cardNumberError}
            onChange={this.onElementChange(
              'creditCardNumberComplete',
              'cardNumberError'
            )}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <StripeTextFieldExpiry
            error={Boolean(expiredError)}
            labelErrorMessage={expiredError}
            onChange={this.onElementChange(
              'expirationDateComplete',
              'expiredError'
            )}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <StripeTextFieldCVC
            error={Boolean(cvcError)}
            labelErrorMessage={cvcError}
            onChange={this.onElementChange('cvcComplete', 'cvcError')}
          />
        </Grid>
      </Grid>
    );
  }
}
