import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useAlert from 'hooks/useAlert';
import { money, isValidTemplate } from 'utils';
import Spin from 'components/Spin';
import OptionsModal from './components/OptionsModal';
import {
  clearCart,
  removeFromCart,
  updateItem,
  calculatePrice,
} from 'store/actions/cart';
import { Alert as InfoAlert, AlertTitle } from '@material-ui/lab';
import TableRow from './components/TableRow';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderLeft: '1px solid #dadada',
    '& th, td': {
      textAlign: 'center',
      padding: '8px',
      borderBottom: '1px solid #dadada',
      borderRight: '1px solid #dadada',
    },
    '& tr': {
      height: '50px',
    },
    '& tr:nth-child(odd)': { backgroundColor: '#f2f2f2' },
    '& th': { backgroundColor: '#fff', borderTop: '1px solid #dadada' },
  },
  tableContainer: {
    margin: '20px auto',
  },
  image: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 100,
    '& img': {
      maxHeight: 60,
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
  caption: {
    whiteSpace: 'nowrap',
    fontSize: 12,
  },
  thBase: {
    minWidth: 125,
  },
  thCrop: {
    width: 40,
  },
  thQty: {
    width: 140,
  },
  thPrice: {
    width: 100,
  },
  thImage: {
    width: 120,
  },
  thProduct: {
    minWidth: 200,
  },
  optionsButton: {
    marginTop: 4,
  },
  footerTD: {
    backgroundColor: '#fff',
  },
  btnLeft: {
    marginRight: 20,
  },
}));

const Cart = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false);
  const [unCropped, setUncropped] = useState(0);
  const [uncroppedRequired, setUncroppedRequired] = useState(0);
  const [needsText, setNeedsText] = useState(0);
  const catalogs = useSelector((s) => s.catalogs);
  const lab = useSelector((s) => s.lab);
  const items = useSelector((s) => s.cart.items);
  const price = useSelector((s) => s.cart.price);
  const updatingPrice = useSelector((s) => s.cart.updatingPrice);
  const alert = useAlert();
  const showBatchThumbnail = items.length < 50;

  useEffect(() => {
    if (items.length) {
      dispatch(calculatePrice());
    }
    const { notCropped, requiresCropping, textMissing } = items.reduce(
      function (acc, item) {
        !item.product.template?.nodes[0]?.crop &&
        item.product.template.nodes[0].image
          ? (acc.notCropped = acc.notCropped + 1)
          : (acc.notCropped = acc.notCropped);
        !item.product.template?.nodes[0]?.crop &&
        item.product.template.nodes[0].image &&
        item?.requireCropping
          ? (acc.requiresCropping = acc.requiresCropping + 1)
          : (acc.requiresCropping = acc.requiresCropping);
        item.product.template?.nodes.some(
          (n) => n.type === 'text' && (!n.text || n.text === 'Your Text Here')
        )
          ? (acc.textMissing = acc.textMissing + 1)
          : (acc.textMissing = acc.textMissing);
        return acc;
      },
      { notCropped: 0, requiresCropping: 0, textMissing: 0 }
    );
    setUncropped(notCropped);
    setUncroppedRequired(requiresCropping);
    setNeedsText(textMissing);
  }, [items]);

  const onClear = () => {
    alert
      .confirm('Are you sure you want to clear the cart contents?')
      .then(() => dispatch(clearCart()));
  };

  const onDelete = (item) => {
    alert
      .confirm('Are you sure you want to delete?')
      .then(() => dispatch(removeFromCart(item)));
  };

  const onUpdateOptions = (options) => {
    showOptions.options = options;
    dispatch(updateItem(showOptions));
    setShowOptions();
  };

  const actions = items.length ? (
    <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        size="medium"
        to="/"
        className={classes.btnLeft}
      >
        Continue Ordering
      </Button>
      <Button
        disabled={!price || uncroppedRequired > 0}
        variant="contained"
        color="secondary"
        component={Link}
        size="medium"
        to="/checkout"
      >
        Checkout
      </Button>
    </Box>
  ) : null;

  return (
    <Box marginTop={2}>
      <Helmet title={`${lab.lab_name} - Shopping Cart`}>
        <meta name="description" content="Cart for Simple Ordering System" />
      </Helmet>
      {updatingPrice && (
        <LinearProgress
          style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1600 }}
        />
      )}
      <Container>
        <Typography variant="h6">Shopping Cart</Typography>

        {actions}
        {unCropped !== 0 && uncroppedRequired === 0 && (
          <InfoAlert severity="info">
            <AlertTitle>You Have {unCropped} Uncropped Images</AlertTitle>Images
            below that have the icon in the 2nd column have not had their
            cropping checked. If the image proportion does not match the
            proportion of the product you ordered they will be center cropped.
            If you need to check the cropping you can do that by clicking the
            Edit icon and then using the left and right arrows to scroll thru
            your images and check the cropping.
          </InfoAlert>
        )}
        {uncroppedRequired !== 0 && (
          <InfoAlert severity="error">
            <AlertTitle>
              Checkout Disabled - Editing Required on {uncroppedRequired} Images
            </AlertTitle>
            Items in the list below with a red crop icon require editing to
            check the cropping. To edit your images from the cart click the
            pencil icon on the right column and then you can use the right arrow
            key to advance thru all the images while staying in the editor. The
            left arrow will take you to the previous image. When you are done
            click the Update Cart button.
          </InfoAlert>
        )}
        {needsText !== 0 && (
          <InfoAlert severity="warning">
            <AlertTitle>
              Did You Forget to Add Text? {needsText} Images Have Text Nodes
              That Were Not Changed
            </AlertTitle>
            Items in the list below with a TT icon have at least one text node
            with default or missing text. This could be on purpose or you might
            have forgotten to enter your text. You can click the Pencil icon to
            edit it and check if it is correct.
          </InfoAlert>
        )}
        {items.length ? (
          <>
            <Paper className={classes.tableContainer} elevation={0}>
              <Spin spinning={!price}>
                <TableVirtuoso
                  style={{ height: 500 }}
                  data={items}
                  components={{
                    Table: (props) => (
                      <table {...props} className={classes.table} />
                    ),
                  }}
                  fixedHeaderContent={() => (
                    <tr>
                      <th
                        className={classes.thImage}
                        title="This is your original uncropped image, cropping does not show on the thumbnail"
                      >
                        File*
                      </th>
                      <th className={classes.thCrop} />
                      <th className={classes.thProduct}>Product</th>
                      <th className={classes.thQty}>Quantity</th>
                      <th className={classes.thPrice}>Price</th>
                      <th className={classes.thBase}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onClear}
                        >
                          Clear Cart
                        </Button>
                      </th>
                    </tr>
                  )}
                  itemContent={(index, i) => (
                    <TableRow
                      key={i.id}
                      classes={classes}
                      i={i}
                      showBatchThumbnail={showBatchThumbnail}
                      onDelete={onDelete}
                      catalogs={catalogs}
                      setShowOptions={setShowOptions}
                      price={price}
                      lab={lab}
                    />
                  )}
                  fixedFooterContent={() => (
                    <tr>
                      <td colSpan="3" className={classes.footerTD}>
                        <Typography
                          variant="caption"
                          component="p"
                          align="left"
                        >
                          * This is your original image without any cropping.
                          This system is unable to show cropping on the cart
                          thumbnails.
                        </Typography>
                      </td>
                      <td colSpan="2" className={classes.footerTD}>
                        <b>
                          Sub Total:{' '}
                          {money(
                            price?.lineItemsTotal,
                            lab.globalOptions.currency
                          )}
                        </b>
                      </td>
                      <td className={classes.footerTD} />
                    </tr>
                  )}
                  computeItemKey={(index, item) => item.id}
                />
              </Spin>
            </Paper>
            {actions}
          </>
        ) : (
          <Box textAlign="center">
            <Typography paragraph variant="subtitle1">
              Your cart is empty.
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/">
              Continue Ordering
            </Button>
          </Box>
        )}
      </Container>
      {showOptions && (
        <OptionsModal
          onClose={() => setShowOptions()}
          handleSubmit={onUpdateOptions}
          item={showOptions}
          currency={lab.globalOptions.currency}
        />
      )}
    </Box>
  );
};

export default Cart;
