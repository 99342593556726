import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootLabel: {
    borderRadius: 4,
    boxShadow: `10 0 0 0.1rem ${theme.palette.grey[100]}`,
    fontSize: 16,
    padding: '0 18px 0 0',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    margin: 0,
    marginRight: 12,
    marginBottom: 0,
  },
  group: {
    'label + &': {},
    // marginTop: theme.spacing(2)
  },
  radioRoot: {
    padding: 6,
    color: theme.palette.primary.main,
  },
  radio: {
    border: '1px solid #ced4da',
    width: 16,
    height: 16,
    borderRadius: 8,
    margin: 2,
  },
  checkedRadio: {
    backgroundColor: theme.palette.primary.main,
    width: 16,
    height: 16,
    borderRadius: 8,
    margin: 2,
  },
  bootstrapFormLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  control: {
    marginBottom: theme.spacing(3),
  },
  hint: {
    display: 'block',
    marginBottom: theme.spacing(1),
    fontSize: 13,
  },
});

class CustomRadio extends PureComponent {
  render() {
    const {
      classes,
      label,
      hint,
      options,
      hasError,
      helpText,
      size,
      defaultIcons,
      ...otherProps
    } = this.props;

    return (
      <div className={classes.root}>
        <FormControl className={classes.control} fullWidth margin={'none'}>
          <FormLabel component="label" className={classes.bootstrapFormLabel}>
            {label}
          </FormLabel>
          {hint && (
            <Typography
              className={classes.hint}
              color="textSecondary"
              variant="caption"
            >
              {hint}
            </Typography>
          )}
          <RadioGroup
            aria-label={label}
            className={classes.group}
            {...otherProps}
          >
            {options.map((option) => (
              <React.Fragment key={option.value}>
                <FormControlLabel
                  {...otherProps}
                  value={option.value}
                  control={
                    <Radio
                      disableRipple
                      classes={{ root: classes.radioRoot }}
                      color={'primary'}
                      size={size}
                    />
                  }
                  style={{ textTransform: 'capitalize' }}
                  label={option.label}
                  classes={{ root: classes.rootLabel }}
                />
                {option.description && (
                  <Typography gutterBottom variant="caption">
                    {option.description}
                  </Typography>
                )}
              </React.Fragment>
            ))}
            {hasError && <FormHelperText error>{helpText}</FormHelperText>}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

CustomRadio.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
};

CustomRadio.defaultProps = {
  options: [],
};

export default withStyles(styles)(CustomRadio);
