import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import StripeInput from './StripeInput';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

function StripeTextField(props) {
  const {
    InputLabelProps,
    labelErrorMessage,
    stripeElement,
    InputProps = {},
    inputProps,
    ...other
  } = props;
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      variant="outlined"
      classes={{
        root: classes.inputRoot,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      {...other}
    />
  );
}

export function StripeTextFieldNumber(props) {
  return (
    <StripeTextField
      label={<Typography variant="body1">{'Credit Card Number'}</Typography>}
      stripeElement={CardNumberElement}
      {...props}
    />
  );
}

export function StripeTextFieldExpiry(props) {
  return (
    <StripeTextField
      label={<Typography variant="body1">{'Expiration Date'}</Typography>}
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeTextFieldCVC(props) {
  return (
    <StripeTextField
      label={<Typography variant="body1">{'CVC Code'}</Typography>}
      stripeElement={CardCvcElement}
      {...props}
    />
  );
}

export function ZipTextField(props) {
  return <StripeTextField label="Zip Code" {...props} />;
}
