import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const nextBase = this.props.location.pathname;
      const previousBase = prevProps.location.pathname;
      // don't scroll if it is same base path
      if (nextBase === previousBase) return;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  render() {
    return null;
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.object
};

export default withRouter(ScrollToTop);
