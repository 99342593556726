import { ADD_PACKAGE, REMOVE_PACKAGE, SET_PACKAGES } from '../actions/packages';

const initialState = [];

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_PACKAGE: {
      return [...state, payload];
    }
    case REMOVE_PACKAGE: {
      return state.filter((p) => p.id !== payload);
    }
    case SET_PACKAGES: {
      return payload;
    }
    default:
      return state;
  }
}

export default reducer;