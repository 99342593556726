import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Input from './Input';

function NumberFormatCustom(props) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.formattedValue,
          },
        });
      }}
      format={(val) => {
        const digits = val.replace(/\D/g, '');
        if (digits.length <= 10) {
          // US format
          return digits.length > 6
            ? `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`
            : digits.length > 3
            ? `${digits.slice(0, 3)}-${digits.slice(3)}`
            : digits.length > 0
            ? `${digits}`
            : '';
        } else {
          // International format
          return `${digits.slice(0, digits.length - 10)} ${digits.slice(
            -10,
            -7
          )} ${digits.slice(-7, -4)}-${digits.slice(-4)}`;
        }
      }}
      allowEmptyFormatting={false}
      mask="_"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function FormikPhoneNumberInput(props) {
  return <Input inputComponent={NumberFormatCustom} {...props} />;
}

FormikPhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormikPhoneNumberInput;
