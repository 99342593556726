import {
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 296,
    border: '1px solid #c5c5c5',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      maxWidth: 280,
      width: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 280,
      width: 'unset',
    },
  },
  item: {
    textAlign: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      border: '1px solid #a7a7a7',
    },
  },
  content: {
    padding: 12,
    flexGrow: 1,
  },
  info: {
    backgroundColor: '#f9f9f9',
    padding: '1rem',
  },
  cat_title: {
    fontSize: '1.1rem',
  },
  buttons: {
    height: 40,
    marginBottom: 8,
  },
  btn: {
    margin: 4,
  },
  video: {
    marginBottom: '12px',
  },
}));
export default function CatalogCard({ cat }) {
  const classes = useStyles();
  ///choose-product/${cat.cat_id}
  //console.log('is mobile?', maxInnerSide < 901 && minInnerSide < 450);
  // const { height, width } = useWindowDimensions();
  // const isMobile = width < 450 || height < 450;
  // console.log('isMobile :>> ', isMobile, height, width);

  const productsWithTemplate = cat.products.filter(
    (i) => i.template?.nodes?.length
  );
  return (
    <Box className={classes.root} variant="outlined">
      {cat.cat_icon && (
        <CardMedia
          component="img"
          alt={cat.cat_name}
          image={cat.cat_icon}
          title={cat.cat_name}
        />
      )}
      <CardContent className={classes.content}>
        <Typography className={classes.cat_title}>{cat.cat_name}</Typography>
        <Typography color="textSecondary">{cat.cat_subtitle}</Typography>
        {cat.hidden && (
          <Typography color="error">This Catalog is Hidden!</Typography>
        )}
      </CardContent>
      <CardActions className={classes.buttons}>
        <Hidden smDown>
          {productsWithTemplate.length && !cat.disable_batch ? (
            <Tooltip
              placement="top-end"
              enterDelay={500}
              enterNextDelay={500}
              title="Use Batch ordering for bulk orders where the cropping has already been done or for files that are not jpegs."
            >
              <Button
                to={`/batch-order/${cat.cat_id}`}
                className={classes.btn}
                size="small"
                fullWidth
                component={Link}
                variant="outlined"
                color="primary"
              >
                Batch Order
              </Button>
            </Tooltip>
          ) : null}
          {productsWithTemplate.length && !cat.disable_cropper ? (
            <Tooltip
              placement="top-end"
              enterDelay={500}
              enterNextDelay={500}
              title="Normal workflow for JPEG and PNG files where you can control the crop."
            >
              <Button
                className={classes.btn}
                fullWidth
                component={Link}
                to={`/editor/${cat.cat_id}`}
                size="small"
                variant="contained"
                color="primary"
              >
                Order Now
              </Button>
            </Tooltip>
          ) : null}
        </Hidden>
        <Hidden mdUp>
          {productsWithTemplate.length &&
          !cat.disable_batch &&
          !cat.require_cropping ? (
            <Tooltip
              placement="top-end"
              enterDelay={500}
              enterNextDelay={500}
              title="Use Batch ordering for bulk orders where the cropping has already been done or for files that are not jpegs."
            >
              <Button
                to={`/batch-order/${cat.cat_id}`}
                className={classes.btn}
                size="small"
                fullWidth
                component={Link}
                variant="outlined"
                color="primary"
              >
                Batch Order
              </Button>
            </Tooltip>
          ) : null}
          {productsWithTemplate.length && !cat.disable_cropper ? (
            <Tooltip
              placement="top-end"
              enterDelay={500}
              enterNextDelay={500}
              title="Normal workflow for JPEG and PNG files where you can control the crop."
            >
              <Button
                className={classes.btn}
                fullWidth
                component={Link}
                to={`/choose-product/${cat.cat_id}`}
                size="small"
                variant="contained"
                color="primary"
              >
                Order Now
              </Button>
            </Tooltip>
          ) : null}
        </Hidden>
      </CardActions>
    </Box>
  );
}
