import { useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ImageNode from './ImageNode';
import TextNode from './TextNode';
import { inchToPixels, getRotationFromImage } from 'utils';

const getOverlayBackground = ({ template }) => {
  const { overlay } = template;
  if (overlay) {
    const { x, y, webOverlay } = overlay;
    return `transparent url(${webOverlay}) no-repeat scroll ${inchToPixels(
      y
    )}px ${inchToPixels(x)}px`;
  } else {
    return undefined;
  }
};

const getOverlaySize = ({ template, zoomRatio }) => {
  if (template.overlay) {
    const { overlay } = template;
    const { width, height } = overlay;
    return `${inchToPixels(width * zoomRatio)}px ${inchToPixels(
      height * zoomRatio
    )}px`;
  }
  return undefined;
};

const overlayStyle = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  pointerEvents: 'none',
  zIndex: 1,
  background: getOverlayBackground,
  backgroundSize: getOverlaySize,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: ({ width, zoomRatio }) => inchToPixels(width) * zoomRatio,
    height: ({ zoomRatio, height }) => inchToPixels(height) * zoomRatio,
    backgroundColor: ({ template }) => template.backgroundColor,
    margin: '.5rem auto',
    outline: '1px solid #ababab',
    '&:after': {
      ...overlayStyle,
      display: ({ template }) =>
        template.overlay?.setToBackground ? 'none' : 'block',
    },
    '&:before': {
      ...overlayStyle,
      display: ({ template }) =>
        template.overlay?.setToBackground ? 'block' : 'none',
    },
  },
}));

const Canvas = ({
  product,
  onUpdateNode,
  onZoom,
  onReady,
  onNodeClick,
  onImageSelect,
  onCrop,
  selectedNodeId,
  editing,
}) => {
  const { template, id } = product;
  const classes = useStyles(product);
  // need to use ref to store the selectedNodeId for hack because cropperjs seems to memo the crop handler.
  const ref = useRef({ selectedNodeId });

  useEffect(() => {
    ref.current = { selectedNodeId };
  }, [selectedNodeId]);

  const handleCrop = (nodeId, cropper) => {
    if (ref.current.selectedNodeId === nodeId) {
      onCrop(nodeId, cropper);
    }
  };

  const hasTextNodes = template.nodes.some((x) => x.type === 'text');

  return (
    <Paper id="sos-canvas" className={classes.root}>
      {template.nodes
        .filter((x) => x.type === 'image')
        .map((imgNode, idx) => (
          <ImageNode
            key={idx + id + imgNode.fitImage + imgNode.id}
            node={imgNode}
            onZoom={onZoom}
            zoomRatio={product.zoomRatio}
            onReady={onReady}
            onImageSelect={onImageSelect}
            onNodeClick={onNodeClick}
            onCrop={handleCrop}
            isSelected={selectedNodeId === imgNode.id}
            hasTextNodes={hasTextNodes}
            editing={editing}
          />
        ))}
      <Box>
        {template.nodes
          .filter((x) => x.type === 'text')
          .map((i, idx) => (
            <TextNode
              node={i}
              key={i.id + idx}
              zoomRatio={product.zoomRatio}
              onChange={onUpdateNode}
            />
          ))}
      </Box>
    </Paper>
  );
};

export default Canvas;
