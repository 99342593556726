import { shortId } from 'utils';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const REMOVE_PACKAGE = 'REMOVE_PACKAGE';

export const SET_PACKAGES = 'SET_PACKAGES';

export const addPackage = (data) => {
  const packageId = shortId();
  const packages = localStorage.getItem('packages');
  const packagesData = packages ? JSON.parse(packages) : [];
  const newPackage = {
    id: packageId,
    ...data
  };
  packagesData.push(newPackage);
  localStorage.setItem('packages', JSON.stringify(packagesData));
  return {
    type: ADD_PACKAGE,
    payload: newPackage
  };
};

export const removePackage = (id) => {
  const packages = localStorage.getItem('packages');
  const packagesData = packages ? JSON.parse(packages) : [];
  const newPackagesData = packagesData.filter((i) => i.id !== id);
  localStorage.setItem('packages', JSON.stringify(newPackagesData));
  return {
    type: REMOVE_PACKAGE,
    payload: id
  };
};