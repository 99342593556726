import { v4 as uuid } from 'uuid';
import { ACTIONS } from '../actions/cart';

const initialState = {
  sessionId: '',
  items: [],
  uploads: {},
  customer: {},
  shippingAddress: {},
  orderOptions: {},
  shippingMethod: undefined,
  orderNotes: '',
  jobRef: '',
  userId: '',
  price: null,
  updatingPrice: false,
  isTaxExemptConfirmed: true,
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.ADD_TO_CART:
      return {
        ...state,
        items: [...state.items, { ...payload }],
      };
    case ACTIONS.REMOVE_FROM_CART:
      return {
        ...state,
        items: state.items.filter((i) => i.id !== payload.item.id),
      };
    case ACTIONS.UPDATE_GLOBAL_OPTION:
      return {
        ...state,
        orderOptions: {
          ...state.orderOptions,
          ...payload
        },
      }
    case ACTIONS.UPDATE_SHIPPING_METHOD:
      return {
        ...state,
        shippingMethod: payload,
      };
    case ACTIONS.UPDATE_CUSTOMER:
      return {
        ...state,
        customer: payload
      };
    case ACTIONS.UPDATE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: payload,
      };
    case ACTIONS.UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((i) =>
          i.id === payload.id ? { ...payload } : i
        ),
      };
    case ACTIONS.UPDATE_CART:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.UPLOAD: {
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [payload.uploadKey]: payload
        }
      }
    }
    case ACTIONS.CLEAR_CART:
      return {
        ...state,
        items: [],
        uploads: {},
        orderOptions: {},
        sessionId: uuid(),
      };
    case ACTIONS.CALCULATING_PRICE:
      return {
        ...state,
        updatingPrice: true,
      }
    case ACTIONS.CALC_PRICE:
      return {
        ...state,
        price: payload,
        updatingPrice: false,
      };
    case ACTIONS.RESET:
      return {
        ...initialState,
        items: [],
        uploads: {},
        orderOptions: {},
        sessionId: uuid(),
      }
    case ACTIONS.RESTORE:
      return {
        ...initialState,
        ...payload,
      }
    default:
      return state;
  }
};

export default reducer;