import { SET_LAB } from '../actions/lab';

const initialState = {
  globalOptions: {},
  customization: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LAB:
      return payload
    default:
      return state;
  }
};

export default reducer;