import { useMemo, useEffect, useCallback, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import cx from 'classnames';
import Cropper from 'react-cropper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { inchToPixels } from 'utils';
import { useDropzone } from 'react-dropzone';
import useKeyPress from 'hooks/useKeyPress';

const devEnvironment = process.env.RAZZLE_APP_STATUS;

const calcNodeDimensions = (node, zoomRatio) => {
  return {
    width: inchToPixels(node.width * zoomRatio),
    height: inchToPixels(node.height * zoomRatio),
    left: inchToPixels(node.x * zoomRatio),
    top: inchToPixels(node.y * zoomRatio),
  };
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    backgroundColor: ({ node }) => (node.image ? '' : 'aliceblue'),
    outline: ({ borderWidth, node }) =>
      borderWidth || node.image ? '' : '1px solid lightblue',
    left: ({ dimensions }) => dimensions.left,
    top: ({ dimensions }) => dimensions.top,
    width: ({ dimensions }) => dimensions.width,
    height: ({ dimensions }) => dimensions.height,
    zIndex: 1,
    '&:hover': {
      outline: ({ borderWidth }) => (borderWidth ? '' : '1px solid lightblue'),
    },
    '&.focused:before': {
      animationName: 'img-selected',
      animationDuration: '1s',
      content: '" "',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      pointerEvents: 'none',
      zIndex: 1,
    },
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
    textAlign: 'center',
    lineHeight: '14px',
  },
  dropzoneActive: {
    backgroundColor: '#eee',
  },
  canvas: {
    position: 'relative',
    cursor: ({ editing }) => (editing ? 'not-allowed' : 'pointer'),
    height: '100%',
    zIndex: 1,
    '& img ': {
      filter: ({ node }) => (node.grayscale ? 'grayscale(100%)' : 'none'),
    },
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      border: ({ borderColor, borderWidth, zoomRatio }) =>
        borderWidth
          ? `${borderWidth * zoomRatio}px solid ${borderColor}`
          : 'none',
      pointerEvents: 'none',
      zIndex: 1,
    },
  },
}));

const ImageNode = ({
  node,
  zoomRatio,
  onImageSelect,
  onReady,
  onNodeClick,
  onCrop,
  isSelected,
  hasTextNodes,
  editing,
}) => {
  const [highlight, setHighlight] = useState(false);
  const timerRef = useRef();
  const dimensions = calcNodeDimensions(node, zoomRatio);
  const clickToAddImageText =
    dimensions.width < 80 || dimensions.height < 80
      ? '...'
      : editing
      ? ''
      : 'Click to Add Image';
  //below is the dnd component but there is also another dropzone component
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'image',
      drop: () => ({ nodeId: node.id }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [node.id]
  );

  useEffect(() => {
    setHighlight(false);
    if (isSelected && node.image) {
      setHighlight(true);
      timerRef.current = setTimeout(() => setHighlight(false), 1000);
    }
    return () => clearTimeout(timerRef.current);
  }, [isSelected]);

  const classes = useStyles({
    node,
    editing,
    dimensions,
    zoomRatio,
    borderWidth: node.borderWidth,
    borderColor: node.ignoreBorderRendering
      ? node.borderColor + '50'
      : node.borderColor,
  });
  const cropperRef = useRef(null);

  const previewImage = useMemo(() => {
    if (node.image) return URL.createObjectURL(node.image);
  }, [node.image]);

  useEffect(() => {
    if (previewImage) {
      return () => URL.revokeObjectURL(previewImage);
    }
  }, [previewImage]);

  const isActive = canDrop && isOver;

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        onFileSelect(acceptedFiles);
      }
    },
    [node]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxFiles: 1,
    multiple: false,
    useFsAccessApi: false,
    disabled: editing,
    onDrop,
  });

  const onCropperReady = () => {
    onReady(node.id, cropperRef);
    if (node.crop && node.canvas) {
      let cropperLeft = -node.crop.left * zoomRatio;
      let cropperTop = -node.crop.top * zoomRatio;
      if (node.fitImage) {
        // fix editing bug where fit allows width to be less than node width/height
        // you have to check if that is the case and do the crop as positive instead of negative
        if (inchToPixels(node.width) > node.canvas.width) {
          cropperLeft = -cropperLeft;
        } else if (inchToPixels(node.height) > node.canvas.height) {
          cropperTop = -cropperTop;
        }
      }
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      cropper.setCanvasData({
        left: cropperLeft,
        top: cropperTop,
        width: node.canvas.width * zoomRatio,
        height: node.canvas.height * zoomRatio,
      });
    }
  };

  const onFileSelect = (files) => {
    const file = files[0];
    onImageSelect(file, node.id);
  };

  const onCanvasClick = () => onNodeClick(node.id);

  const onChange = () => {
    const cropper = cropperRef.current.cropper;
    onCrop(node.id, cropper);
  };

  const onZoom = () => {
    onCanvasClick();
  };

  const onHotKey = useCallback(
    (event) => {
      const imageElement = cropperRef.current;
      const cropper = imageElement?.cropper;
      if (hasTextNodes || !cropper) return;
      if (event.key === '+' || event.key === 'q') {
        cropper.zoom(0.08);
      }
      if (event.key === '-' || event.key === '`') {
        cropper.zoom(-0.08);
      }
      if (event.key === 'z') {
        cropper.zoomTo(0.01);
      }
    },
    [hasTextNodes]
  );

  useKeyPress(['+', '-', '`', 'q', 'z'], onHotKey);

  return (
    <div
      {...getRootProps({
        className: cx(classes.root, { focused: highlight }),
        id: `image-node-${node.id}`,
        onClick: onCanvasClick,
      })}
      ref={drop}
    >
      {!node.image ? (
        <Box
          title={
            devEnvironment === 'testing'
              ? `Image Node ${node.id}`
              : editing
              ? "you can't add or change an image while editing"
              : ''
          }
          className={cx(classes.canvas, classes.dropzone, {
            [classes.dropzoneActive]: isActive || isDragActive,
          })}
        >
          {isActive ? 'Release to drop' : clickToAddImageText}
          <input {...getInputProps()} />
        </Box>
      ) : (
        <Cropper
          className={classes.canvas}
          style={{
            height: dimensions.height,
            width: dimensions.width,
            overflow: 'hidden',
          }}
          src={previewImage}
          autoCropArea={1}
          dragMode="move"
          viewMode={node.fitImage ? 2 : 3}
          rotatable={true}
          guides={false}
          center={false}
          wheelZoomRatio={0.04}
          highlight={false}
          toggleDragModeOnDblclick={false}
          cropBoxResizable={false}
          cropBoxMovable={false}
          background={false}
          modal={false}
          checkOrientation={true}
          ref={cropperRef}
          zoom={onZoom}
          rotateTo={node.rotate}
          ready={onCropperReady}
          crop={onChange}
          cropstart={onCanvasClick}
          minContainerWidth={15}
          minContainerHeight={15}
        />
      )}
    </div>
  );
};

export default ImageNode;
