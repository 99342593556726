import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Plausible from 'plausible-tracker';
import { useSelector } from 'react-redux';

export default () => {
  const lab = useSelector((s) => s.lab);

  const location = useLocation();

  const { trackPageview } = Plausible({
    domain: 'soslabs.net, global',
  });

  useEffect(() => {
    trackPage();
  }, [location]);

  const trackPage = () => {
    //bailout if localhost
    if (['localhost', '127.0.0.1', ''].includes(window.location.hostname)) {
      return null;
    } else {
      //plausible wants full url not just the relative path
      trackPageview({
        url: `${window.location.href} -- ${lab.lab_name}`,
      });
    }
  };

  return null;
};
