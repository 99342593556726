import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ProductCard from 'components/ProductCard';
import {
  getZoom,
  shortId,
  getRotationFromImage,
  applyCanvasRotation,
  allowedFile,
  isImage,
  isValidTemplate,
  getFileHash,
} from 'utils';
import cloneDeep from 'lodash/cloneDeep';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle } from '@material-ui/lab';
import { addToCart, uploadItem } from 'store/actions/cart';
import CreatePackage from './components/CreatePackage';
import PackagesList from './components/PackagesList';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles({
  products: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  file: {
    visibility: 'hidden',
  },
  button: {
    marginLeft: '1rem',
  },
});

const BatchOrder = ({
  match: {
    params: { category },
  },
  history,
}) => {
  const fileRef = useRef();
  const classes = useStyles();
  const [showAddPackage, setShowAddPackage] = useState(false);
  const [progress, setProgress] = React.useState({
    total: 0,
    completed: 0,
    file: '',
  });
  const catalogs = useSelector((s) => s.catalogs);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedProduct, setSelectedProduct] = useState();
  const dispatch = useDispatch();
  const lab = useSelector((s) => s.lab);
  const packages = useSelector((s) => s.packages).filter(
    (i) => i.catId === category
  );
  const catalog = useMemo(
    () => catalogs.find((c) => c.cat_id === category),
    [category]
  );
  const allowedExtensions =
    catalog.allowed_file_types || lab.globalOptions.allowed_file_types;

  const onProductClick = (product) => (e) => {
    setSelectedProduct(product);
    fileRef.current.click();
  };

  const addItemToCart = async (selectedProduct, file, quantity, pkg) => {
    const product = cloneDeep(selectedProduct);
    const isImageFile = isImage(file);
    const hasTemplate = isValidTemplate(product);
    if (isImageFile && hasTemplate) {
      product.zoomRatio = getZoom(selectedProduct);
      product.template.nodes[0].image = file;
    }
    product.template.nodes[0].filename = file.name;
    product.template.nodes[0].fileHash = getFileHash(file);
    const payload = {
      id: shortId(),
      product,
      quantity,
      category,
      requireCropping: catalog.require_cropping,
    };

    if (pkg) {
      payload.pkg = pkg.id;
    }

    if (isImageFile && hasTemplate) {
      if (
        selectedProduct.template.nodes?.length === 1 &&
        !selectedProduct.template.overlay
      ) {
        const rotate = await getRotationFromImage(
          file,
          product.template.nodes[0]
        );
        if (rotate) {
          applyCanvasRotation(product);
        }
      }
    }
    dispatch(addToCart(payload));
    dispatch(uploadItem(file));
  };

  const onFilesSelect = async (e) => {
    const { files } = e.target;
    if (!files.length) return;

    const selectedFiles = Array.from(files);
    await handleFiles(selectedFiles, selectedProduct);
  };

  const onPackageFilesSelect = async (files, pkg) => {
    await handleFiles(files, null, pkg);
  };

  const handleFiles = async (files, product, pkg) => {
    const extensions = allowedExtensions && allowedExtensions.split(',');
    const filteredFiles = files.filter((file) =>
      allowedFile(extensions, file.name)
    );
    if (filteredFiles.length) {
      setProgress({ total: filteredFiles.length, completed: 0 });

      let itemsCount = 0;

      for (const file of filteredFiles) {
        setProgress((p) => ({
          ...p,
          file: file.name,
        }));
        if (pkg) {
          for (const i of pkg.items) {
            const { quantity, productId } = i;
            const p = catalog.products.find((p) => p.id === productId);
            await addItemToCart(p, file, quantity, pkg);
            itemsCount++;
          }
        } else {
          await addItemToCart(product, file, 1);
          itemsCount++;
        }
        setProgress((p) => ({
          ...p,
          completed: p.completed + 1,
        }));
      }
      enqueueSnackbar(`${itemsCount} items added to cart`, {
        variant: 'success',
      });
      console.log(
        `Batch order - ${itemsCount} items added to cart from ${
          filteredFiles.length
        } files. ${pkg ? ' Package Item' : ''}`
      );
      Sentry.captureMessage(
        `Batch order added to cart. ${pkg ? ' Package Item' : ''}`
      );
    }
    if (files.length !== filteredFiles.length) {
      alert(
        `You have selected ${
          files.length - filteredFiles.length
        } invalid file(s). Allowed file types are ${allowedExtensions}`
      );
    }
    if (filteredFiles.length && !catalog.preserve_on_add_to_cart) {
      history.push('/cart');
    }
  };

  const onCreatePackage = () => {
    Sentry.captureMessage('Create Package Button Clicked');
    setShowAddPackage(true);
  };

  const showProgress =
    progress.total > 0 && progress.completed < progress.total;

  return (
    <Box marginTop={6}>
      <Helmet title={`${lab.lab_name} Batch Order - ${catalog.cat_name}`}>
        <meta
          name="description"
          content="Batch order your files for printing - bypassing cropping."
        />
      </Helmet>

      <Container>
        <Typography align="center" variant="h1">
          {catalog.cat_name} - Batch Ordering
        </Typography>{' '}
        <Typography paragraph align="center">
          {catalog.cat_description}.
        </Typography>
        {catalog.require_cropping ? (
          <Alert severity="warning">
            <AlertTitle>Cropping Required</AlertTitle>
            This catalog requires that you check the cropping of all items
            ordered before you can checkout.
            <p>
              Once you add everything to the cart, click the Pencil/Edit icon on
              the first item in the cart, which will load that item into the
              editor where you can crop it. When you are done with the first
              image use the Right Arrow key on your keyboard to load the next
              image. you can use the Left Arrow key to load the previous image.
              This is the quickest way to crop your images.
            </p>
            <p>
              While editing the images, please allow the next/previous image to
              load before clicking the arrow key again.
            </p>{' '}
            <p>
              Batch Ordering is not available for products that allow text
              input.
            </p>
          </Alert>
        ) : (
          <Alert severity="info">
            <AlertTitle>Files Will be Added Directly to the Cart</AlertTitle>
            This workflow will add all of your images directly to the cart,
            skipping the crop screen. If your images are not proportional to the
            size selected and you do not edit the cropping they will be center
            cropped.
            <p>
              You can review the cropping by clicking the Pencil/Edit icon on an
              item in the cart. This will load that image into the editor where
              you can crop it as needed. You can then click the Right Arrow key
              on your keyboard to load the next image. This is the most
              efficient way to crop a large number of images.
            </p>
            <p>
              Batch Ordering is not available for products that allow text
              input.
            </p>
          </Alert>
        )}
        {catalog.allow_packages && (
          <>
            <Box textAlign="right" my={2}>
              <Button
                title="Watch a short video on using Packages"
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<YouTubeIcon />}
                target="_blank"
                href="https://www.youtube.com/watch?v=eAUlSe3yHjk"
              >
                Info
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={onCreatePackage}
              >
                Create A Package
              </Button>
            </Box>
            {packages.length > 0 && (
              <Box mb={3}>
                <PackagesList
                  catalog={catalog}
                  packages={packages}
                  onFilesSelect={onPackageFilesSelect}
                />
              </Box>
            )}
          </>
        )}
        <Box className={classes.products} mt={2}>
          {catalog.products.map((i) => {
            if (i.template?.nodes.length === 1) {
              return (
                <ProductCard
                  key={i.id}
                  product={i}
                  onSelect={onProductClick(i)}
                  showPrice={catalog.cat_show_prices}
                />
              );
            } else {
              return null;
            }
          })}
        </Box>
      </Container>
      <input
        multiple
        value=""
        onChange={onFilesSelect}
        ref={fileRef}
        id="fileInput"
        type="file"
        className={classes.file}
      />
      {showAddPackage && (
        <CreatePackage
          catalog={catalog}
          products={catalog.products}
          onClose={() => setShowAddPackage(false)}
        />
      )}
      {showProgress && (
        <Snackbar open autoHideDuration={6000}>
          <Alert severity="info" variant="filled" elevation={6}>
            Processing file {progress.file} - {progress.completed}/
            {progress.total} items complete
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default BatchOrder;
