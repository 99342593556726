import { SET_CATALOGS } from '../actions/catalogs';

const initialState = [];

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CATALOGS: {
      return payload;
    }
    default:
      return state;
  }
};

export default reducer;