import { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, Field, Formik, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MuiLink from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';
import { updateAddress, updateCustomer } from 'store/actions/cart';
import { TextField, PhoneNumberInput } from 'components/form/Formik';
import Select from 'components/form/Formik/FormikReactSelect';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import clipboard from 'utils/clipboard';
import { parseAddress, recoverAccountId } from 'utils/api';
import get from 'lodash/get';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import useAlert from 'hooks/useAlert';

const validateSchema = Yup.object().shape({
  customer: Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Required'),
  }),
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  btnxl: {
    height: '40px',
    margin: '20px 10px',
  },
}));

const required = (val) => {
  if (!val) return 'Required';
};

const CustomerInfoForm = ({ isPickup, countries, shippingStates }) => {
  const form = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();
  const alert = useAlert();
  const { globalOptions } = useSelector((s) => s.lab);

  useEffect(() => {
    const customerDetails = localStorage.getItem('customerDetails');
    if (customerDetails) {
      form.setFieldValue('customer', JSON.parse(customerDetails));
    }
  }, []);

  const onSendAccountUmber = (e) => {
    e.preventDefault();
    alert.prompt({
      title: 'Check for Lab Account Number',
      description:
        'Enter your email address and we will send you an email with your account number.',
      onConfirm: async (value) => {
        try {
          await recoverAccountId(value);
          enqueueSnackbar('Email Sent!', {
            variant: 'success',
          });
        } catch (e) {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      },
    });
  };

  return (
    <Box>
      {globalOptions.require_userid && (
        <>
          <TextField
            fullWidth
            type="number"
            name="customer.userId"
            label="Account Number (*optional, leave blank if you don't know it)"
            onWheel={(event) => event.target.blur()}
          />
          <Typography variant="caption">
            *If you are a reseller the Account Number is Required.{' '}
            <MuiLink href="#" onClick={onSendAccountUmber}>
              Need your Account Number?
            </MuiLink>
          </Typography>
        </>
      )}
      <TextField
        fullWidth
        label="First Name"
        name="customer.firstName"
        validate={required}
      />
      <TextField
        fullWidth
        label="Last Name"
        name="customer.lastName"
        validate={required}
      />
      <TextField
        fullWidth
        label="Business Name (optional)"
        name="customer.businessName"
      />
      <PhoneNumberInput
        fullWidth
        type="tel"
        label="Phone"
        name="customer.phone"
        validate={required}
      />
      <TextField
        fullWidth
        type="email"
        label="Email"
        name="customer.email"
        validate={required}
      />
      {!isPickup && (
        <>
          <TextField
            fullWidth
            label="Address"
            name="customer.address1"
            validate={required}
          />
          <TextField
            fullWidth
            label="Address line 2"
            name="customer.address2"
          />
          <TextField
            fullWidth
            label="City"
            name="customer.city"
            validate={required}
          />
          {countries.length > 1 ? (
            <Field
              fullWidth
              transformValue
              label="Country"
              name="customer.country"
              component={Select}
              options={countries.map((i) => ({
                label: i.country.name,
                value: i.country.code,
              }))}
              validate={required}
            />
          ) : null}
          <Field
            fullWidth
            transformValue
            component={Select}
            label="State / Province"
            name="customer.state"
            options={shippingStates}
            validate={required}
          />
          <TextField
            fullWidth
            label="Zip"
            name="customer.zip"
            validate={required}
          />
        </>
      )}
    </Box>
  );
};

const DropshipAddressForm = ({ countries }) => {
  const form = useFormikContext();
  const { shippingAddress } = form.values;

  const shippingStates = shippingAddress?.country
    ? countries
        .find((i) => i.country.code === shippingAddress.country)
        .states.map((i) => ({ label: i.name, value: i.short }))
    : [];

  const onPaste = () => {
    clipboard((text) => {
      parseAddress(text).then((resp) => {
        const result = get(resp, '0');
        if (result) {
          const country = countries.find(
            (s) => s.country.name === result.CountryName
          );
          const data = {
            name: result.recipient,
            address1: result.address1,
            address2: result.address2,
            city: result.city,
            state: result.state.length != 2 ? '' : result.state,
            zip: result.zip_code,
            country: country ? country.country.code : 'US',
          };
          form.setFieldValue('shippingAddress', data);
        }
      });
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Drop Ship Address</Typography>
        <Tooltip title="Paste address from Clipboard" placement="top">
          <IconButton
            size="small"
            onClick={onPaste}
            aria-label="paste address from clipboard"
          >
            <LibraryBooksIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <TextField
          fullWidth
          label="Name"
          name="shippingAddress.name"
          validate={required}
        />
        <TextField
          fullWidth
          label="Business Name (optional)"
          name="shippingAddress.businessName"
        />
        <TextField
          fullWidth
          label="Address"
          name="shippingAddress.address1"
          validate={required}
        />
        <TextField
          fullWidth
          label="Address line 2"
          name="shippingAddress.address2"
        />
        <TextField
          fullWidth
          label="City"
          name="shippingAddress.city"
          validate={required}
        />
        {countries.length > 1 ? (
          <Field
            fullWidth
            transformValue
            label="Country"
            name="shippingAddress.country"
            component={Select}
            options={countries.map((i) => ({
              label: i.country.name,
              value: i.country.code,
            }))}
            validate={required}
          />
        ) : null}
        <Field
          fullWidth
          transformValue
          component={Select}
          label="State / Province"
          name="shippingAddress.state"
          options={shippingStates}
          validate={required}
        />
        <TextField
          fullWidth
          label="Zip"
          name="shippingAddress.zip"
          validate={required}
        />
      </Box>
    </>
  );
};

const Address = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector((s) => s.cart);
  const { globalOptions, customization } = useSelector((s) => s.lab);

  const onSubmit = (values) => {
    localStorage.setItem('customerDetails', JSON.stringify(values.customer));
    dispatch(updateCustomer(values.customer));
    if (values.shippingAddress) {
      dispatch(updateAddress(values.shippingAddress));
    }
    history.push('/checkout/pay');
  };

  if (!cart.shippingMethod) {
    return <Redirect to="/checkout" />;
  }

  const isDropship = cart.shippingMethod.shiptype == 'dropship';
  const isPickup = cart.shippingMethod.shiptype === 'pickup';
  const { shipping_countries } = globalOptions;
  const { customer, shippingAddress } = cart;

  return (
    <Container>
      <Box mt={4} mb={4}>
        <Formik
          validationSchema={validateSchema}
          initialValues={{
            customer: {
              firstName: '',
              lastName: '',
              businessName: '',
              phone: '',
              email: '',
              address1: '',
              address2: '',
              city: '',
              country:
                shipping_countries.length > 1
                  ? ''
                  : shipping_countries[0].country.code,
              state: '',
              zip: '',
            },
            shippingAddress: {
              name: shippingAddress?.name,
              businessName: shippingAddress?.businessName,
              address1: shippingAddress?.address1,
              address2: shippingAddress?.address2,
              city: shippingAddress?.city,
              country: shippingAddress?.country
                ? shippingAddress.country
                : shipping_countries.length > 1
                ? ''
                : shipping_countries[0].country.code,
              state: shippingAddress?.state,
              zip: shippingAddress?.zip,
            },
          }}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit }) => {
            const shippingStates = values.customer.country
              ? globalOptions.shipping_countries
                  .find((i) => i.country.code === values.customer.country)
                  .states.map((i) => ({ label: i.name, value: i.short }))
              : [];

            return (
              <Form>
                {customization.customer_alert && (
                  <Box mb={2}>
                    <Alert severity="info">
                      {customization.customer_alert}
                    </Alert>
                  </Box>
                )}
                <Grid container spacing={3} justifyContent="center">
                  <Grid item lg={6} xs={12}>
                    <Typography variant="h6">Customer Info</Typography>
                    <CustomerInfoForm
                      isPickup={isPickup}
                      countries={shipping_countries}
                      shippingStates={shippingStates}
                    />
                  </Grid>
                  {isDropship && (
                    <Grid item lg={6} xs={12}>
                      <DropshipAddressForm countries={shipping_countries} />
                    </Grid>
                  )}
                </Grid>
                <Box maxWidth={270} margin="auto">
                    <Button
                      variant="outlined"
                      color="primary"
                      component={Link}
                      to="/checkout/shipping"
                    >
                      Back
                    </Button>
                    <Button
                      className={classes.btnxl}
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={handleSubmit}
                    >
                      Continue Checkout
                    </Button>
                  </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Container>
  );
};

export default Address;
