import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#4E4E4E',
    textAlign: 'center',
    color: '#ababab',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root} padding={1}>
      &copy; {new Date().getFullYear()} Simple Order System
    </Box>
  );
};

export default Footer;
