import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { money } from 'utils';
import { updateGlobalOption } from 'store/actions/cart';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  optionItem: {
    marginBottom: '.5rem',
    border: '1px solid silver',
    borderRadius: 4,
    color: 'rgba(0, 0, 0, 0.77)',
    maxWidth: 770,
    position: 'relative',
    paddingLeft: '40px', // Reduced padding to accommodate smaller icon
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '18px', // Reduced from 24px
      height: '18px', // Reduced from 24px
      borderRadius: '50%',
      border: '2px solid currentColor',
      boxSizing: 'border-box',
    },
    '&.Mui-selected': {
      backgroundColor: '#f0f0f0', // Gray background
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 600,
      '&:before': {
        border: '2px solid currentColor',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '14px', // Adjusted to center in the smaller circle
        top: '50%',
        transform: 'translateY(-50%)',
        width: '10px', // Reduced from 12px
        height: '10px', // Reduced from 12px
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#f0f0f0', // Keep the same background on hover when selected
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  optionGroup: {
    marginBottom: '1rem',
  },
  optionTitle: {
    marginBottom: '0.5rem',
  },
  optionSubtitle: {
    marginBottom: '0.5rem',
  },
  optionList: {
    padding: 0,
  },
  req: {
    '&::after': {
      content: '"Choice Required"',
      color: 'red',
      paddingLeft: 8,
      fontSize: 14,
    },
  },
}));

const GlobalOptions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { globalOptions } = useSelector((s) => s.lab);
  const { orderOptions = {} } = useSelector((s) => s.cart);

  const handleOptionChange = (optionId, value) => {
    dispatch(
      updateGlobalOption({
        [optionId]: value,
      })
    );
  };

  return (
    <Box>
      {globalOptions.global_checkout_options.map((option) => (
        <Box key={option.id} className={classes.optionGroup}>
          <Typography
            variant="h6"
            className={`${classes.optionTitle} ${
              option.required ? classes.req : ''
            }`}
          >
            {option.description}
          </Typography>
          <Typography color="textPrimary" className={classes.optionSubtitle}>
            {option.subtitle}
          </Typography>
          <List className={classes.optionList}>
            {option.choices.map((choice) => {
              const pcontotal = choice.pcontotal
                ? ` + ${choice.pcontotal}% of cart total`
                : '';
              const label =
                choice.label +
                ' - ' +
                money(choice.price, globalOptions.currency) +
                (choice.priceperfile ? ' per file' : '') +
                pcontotal;

              return (
                <ListItem
                  key={choice.id}
                  button
                  selected={orderOptions[option.id] === choice.id}
                  onClick={() => handleOptionChange(option.id, choice.id)}
                  className={classes.optionItem}
                  disableRipple
                >
                  <ListItemText primary={label} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      ))}
    </Box>
  );
};

export default GlobalOptions;
