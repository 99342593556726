import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const components = {
  IndicatorSeparator: null,
};

const customStyles = {
  container: (provided) => ({
    ...provided,
    'label + &': {
      marginTop: 8,
    },
  }),
  control: (provided, { theme }) => ({
    ...provided,
    'borderRadius': 0,
    'boxShadow': null,
    'transition': 'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      borderColor: theme.colors.primary,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '4px 18px',
    fontSize: 14,
  }),
  placeholder: (provided, { theme }) => ({
    ...provided,
    color: theme.colors.primary,
    fontWeight: 400,
    opacity: 0.42,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99,
    overflow: 'hidden',
  }),
  option: (provided) => ({
    ...provided,
    fontWeight: 400,
    fontSize: 14,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
    fontWeight: 400,
  }),
};

const getTheme = (muiTheme) => (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    spacing: {
      baseUnit: 4,
      controlHeight: 42,
      menuGutter: 8,
    },
    colors: {
      ...theme.colors,
      neutral0: muiTheme.palette.background.paper,
      neutral5: muiTheme.palette.action.disabledBackground,
      neutral10: muiTheme.palette.grey['100'],
      primary: muiTheme.palette.text.primary,
      primary25: muiTheme.palette.action.hover,
      neutral30: muiTheme.palette.grey['300'],
      neutral20: muiTheme.palette.grey['300'],
      neutral80: muiTheme.palette.text.primary,
    },
  };
};

const CustomSelect = React.forwardRef(({
  className,
  placeholder = 'Please select',
  label,
  hint,
  options,
  value,
  onChange,
  hasError,
  helpText,
  loadOptions,
  paginate,
  transformValue,
  isMulti = false,
  cacheOptions = true,
  size,
  styles,
  sx,
  fullWidth,
  margin = 'normal',
  ...rest
}, ref) => {
  const muiTheme = useTheme();

  const handleChange = (value, action) => {
    if (transformValue) {
      onChange(value.value);
    } else {
      onChange(value, action);
    }
  };

  const getValue = () => {
    if (transformValue && options) {
      let option = null;
      options.find((i) => {
        if (i.options) {
          const isMatch = i.options.find((i) => i.value === value);
          if (isMatch) {
            option = isMatch;
          }
          return isMatch;
        }
        const isMatch = i.value === value;
        if (isMatch) {
          option = i;
        }
        return isMatch;
      });
      return option;
    } else {
      return value;
    }
  };

  return (
    <FormControl
      variant="standard"
      margin={margin}
      className={className}
      sx={sx}
      fullWidth={fullWidth}
      error={hasError}
    >
      {label && <FormLabel>{label}</FormLabel>}
      {loadOptions ? (
        <AsyncSelect
          ref={ref}
          theme={getTheme(muiTheme)}
          className={cx('react-select', size)}
          components={components}
          cacheOptions={cacheOptions}
          loadOptions={loadOptions}
          defaultOptions={options}
          placeholder={placeholder}
          onChange={handleChange}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          styles={customStyles}
          value={getValue()}
          {...rest}
        />
      ) : (
        <Select
          ref={ref}
          theme={getTheme(muiTheme)}
          className={cx('react-select', size)}
          components={components}
          placeholder={placeholder}
          options={options}
          value={getValue()}
          onChange={handleChange}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          styles={customStyles}
          {...rest}
        />
      )}
      {hint && (
        <Typography variant="caption" color="textSecondary">
          {hint}
        </Typography>
      )}
      {hasError && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
});


CustomSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }),
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  transformValue: PropTypes.bool,
};


export default CustomSelect;