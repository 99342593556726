import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import { getShippingTotal } from 'utils/priceHelpers';
import { Grid, Typography } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import IconButton from '@material-ui/core/IconButton';
import {
  setShippingMethod,
  calculatePrice,
  updateCart,
} from 'store/actions/cart';
import GlobalOptions from './GlobalOptions';
import Tooltip from '@material-ui/core/Tooltip';
import clipboard from 'utils/clipboard';
import { money } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  shipping: {
    marginBottom: '.5rem',
    border: '1px solid silver',
    borderRadius: 4,
    color: 'rgba(0, 0, 0, 0.77)',
    maxWidth: 770,
    position: 'relative',
    paddingLeft: '40px', // Reduced padding to accommodate smaller icon
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '18px', // Reduced from 24px
      height: '18px', // Reduced from 24px
      borderRadius: '50%',
      border: '2px solid currentColor',
      boxSizing: 'border-box',
    },
    '&.Mui-selected': {
      backgroundColor: '#f0f0f0', // Reverted to the gray background
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 600,
      '&:before': {
        border: '2px solid currentColor',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '14px', // Adjusted to center in the smaller circle
        top: '50%',
        transform: 'translateY(-50%)',
        width: '10px', // Reduced from 12px
        height: '10px', // Reduced from 12px
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#f0f0f0', // Keep the same background on hover when selected
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnxl: {
    height: '40px',
    margin: '20px 10px',
  },
  req: {
    '&::after': {
      content: '"Choice Required"',
      color: 'red',
      paddingLeft: 8,
      fontSize: 14,
    },
  },
  shipH6: {
    fontSize: '1rem',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 4, // Match the border radius of shipping options
    },
  },
}));

const Shipping = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector((s) => s.cart);
  const { globalOptions } = useSelector((s) => s.lab);
  const snack = useSnackbar();

  useEffect(() => {
    dispatch(calculatePrice());
  }, [cart.shippingMethod, cart.orderOptions]);

  const onChange = (item) => (e) => {
    dispatch(setShippingMethod(item));
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    dispatch(
      updateCart({
        [name]: value,
      })
    );
  };

  const onPaste = () => {
    clipboard((text) => {
      onChangeInput({
        target: {
          name: 'jobRef',
          value: text,
        },
      });
    });
  };

  const onNext = () => {
    if (globalOptions.global_checkout_options?.length) {
      const isInvalid = globalOptions.global_checkout_options.some(
        (i) => i.required && !cart.orderOptions[i.id]
      );
      if (isInvalid) {
        return snack.enqueueSnackbar('Please select all the required options');
      }
    }
    history.push('/checkout/address');
  };

  return (
    <Container fixed>
      <Box mt={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item lg={8} xs={12}>
            <GlobalOptions />
            <Typography variant="h6" className={classes.req}>
              Shipping Options
            </Typography>
            <Typography>Please choose a shipping option below.</Typography>

            <List className={classes.root}>
              {globalOptions.shipping.map((i) => (
                <ListItem
                  className={classes.shipping}
                  button
                  key={i.id}
                  selected={cart.shippingMethod?.id === i.id}
                  onClick={onChange(i)}
                  disableRipple // Add this line to disable the ripple effect
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" className={classes.shipH6}>
                        {i.shippingmethod} -{' '}
                        {cart.price
                          ? money(
                              getShippingTotal(
                                i.id,
                                cart.price.lineItems,
                                cart.price.lineItemsTotal,
                                cart.price.optionsTotal,
                                globalOptions.shipping
                              ),
                              globalOptions.currency
                            )
                          : null}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2">{i.description}</Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Typography variant="h6">Order Notes</Typography>
            <Typography>
              If you need to tell us something special about your order leave a
              note below.
            </Typography>
            <TextField
              fullWidth
              name="orderNotes"
              variant="outlined"
              label="Special Instructions (optional)"
              margin="normal"
              value={cart.orderNotes}
              onChange={onChangeInput}
              className={classes.textField} // Add this line
            />
            <TextField
              name="jobRef"
              variant="outlined"
              label="PO / Job Reference (optional)"
              margin="normal"
              value={cart.jobRef}
              style={{ width: 310 }}
              onChange={onChangeInput}
              className={classes.textField} // Add this line
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="Paste PO / Job Reference from Clipboard"
                      placement="top"
                    >
                      <IconButton onClick={onPaste}>
                        <LibraryBooksIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} mb={1} textAlign="center">
        <Button
          variant="outlined"
          size="medium"
          className={classes.btnxl}
          color="primary"
          component={Link}
          to="/cart"
        >
          Back
        </Button>
        <Button
          disabled={!cart.shippingMethod}
          size="medium"
          className={classes.btnxl}
          variant="contained"
          color="secondary"
          onClick={onNext}
        >
          Continue Checkout
        </Button>
      </Box>
    </Container>
  );
};

export default Shipping;
