import axios from 'axios';
import * as Sentry from '@sentry/react';

const errorResponse = (err) => {
  if (err.response?.data) {
    return Promise.reject(err.response.data);
  } else {
    return Promise.reject(err);
  }
};

export const adminClient = axios.create({
  baseURL: process.env.REACT_ADMIN_API,
});

const sosClient = axios.create({
  baseURL: '/api',
});

adminClient.interceptors.response.use((resp) => resp.data, errorResponse);
sosClient.interceptors.response.use((resp) => resp.data, errorResponse);

export const uploadImage = async (
  retry,
  file,
  fileKey,
  folderName,
  onUploadProgress
) => {
  const maxRetries = 3;

  let retryCount = 0;
  let key = null;
  let url = null;
  Sentry.addBreadcrumb({
    category: 'upload',
    message: 'Uploading file',
    level: 'warning',
    data: {
      name: fileKey,
      fileSize: file.size,
      fileType: file.type,
      folder: folderName,
    },
  });
  while (retryCount < maxRetries) {
    try {
      const response = await sosClient.post('cart/signed-url', {
        type: file.type,
        name: fileKey,
        folder: folderName,
      });
      key = response.key;
      url = response.url;

      if (!url || !key) {
        throw new Error('Invalid response from signed-url endpoint');
      }

      try {
        // Primary method: Direct upload to S3
        const putResponse = await axios.put(url, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress,
        });

        if (putResponse.status >= 200 && putResponse.status < 300) {
          return { key };
        } else {
          throw new Error(`Upload failed with status ${putResponse.status}`);
        }
      } catch (putError) {
        // Fallback method, form data sent to /upload-fallback api using sdk
        Sentry.captureMessage('Direct upload failed, attempting fallback');

        const formData = new FormData();
        formData.append('file', file);
        formData.append('key', fileKey);
        formData.append('folder', folderName);

        const fallbackResponse = await sosClient.post(
          'cart/upload-fallback',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
          }
        );
        if (fallbackResponse && fallbackResponse.success) {
          Sentry.captureMessage('fallback upload successful');
          return { key: fallbackResponse.key };
        } else {
          throw new Error('Fallback upload failed');
        }
      }
    } catch (error) {
      retryCount++;
      Sentry.addBreadcrumb({
        category: 'upload',
        message: `Upload attempt ${retryCount} failed - both direct and fallback methods failed`,
        level: 'error',
        data: {
          errorMessage: error.message,
          errorResponse: error.response?.data,
          errorStatus: error.response?.status,
          errorHeaders: error.response?.headers,
          fileSize: file.size,
          fileType: file.type,
        },
      });

      Sentry.captureException(error, {
        tags: { function: 'uploadImage' },
        extra: {
          retryCount,
          fileKey,
          folderName,
          errorMessage: error.message,
          errorResponse: error.response?.data,
        },
      });

      if (retryCount === maxRetries) {
        // If retry is false, throw an error to stop the upload
        if (!retry) {
          throw new Error(
            `Failed to upload after ${maxRetries} attempts: ${error.message}`
          );
        }
        // If retry is true, return the key to the caller
        Sentry.captureMessage(
          'Upload failed after retry click, skipping upload, image will be missing'
        );
        return { key };
      }

      // Exponential backoff
      const backoffTime = 100 * Math.pow(2, retryCount);
      await new Promise((resolve) => setTimeout(resolve, backoffTime));
    }
  }
};

export const calculatePrice = (data) => {
  return sosClient.post('cart/price', data);
};

export const createCart = (data) => sosClient.post('cart', data);

export const createOrder = (data) => sosClient.post('orders', data);

export const parseAddress = (text) =>
  axios
    .post(
      'https://qjn81caq90.execute-api.us-west-2.amazonaws.com/prod/parse-address',
      text
    )
    .then((resp) => resp.data);

export const recoverAccountId = (email) =>
  sosClient.post('cart/recover-account', { email });
