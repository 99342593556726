import { ADD_TO_GALLERY, CLEAR_GALLERY } from '../actions/app';

const initialState = {
  gallery: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_GALLERY:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          ...payload,
        }
      }
    case CLEAR_GALLERY:
      return {
        ...state,
        gallery: {}
      };
    default:
      return state;
  }
};

export default reducer;