import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LazyLoad from 'react-lazyload';
import { addToGallery, clearGallery } from 'store/actions/app';
import Thumbnail, { Placeholder } from './Thumbnail';
import { Typography } from '@material-ui/core';
import { getFileHash } from 'utils';
import { genThumb } from 'hooks/useThumbnail';
import { setInCache } from 'utils/img-cache';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    [theme.breakpoints.up('lg')]: {
      width: 300,
    },
    [theme.breakpoints.up('xl')]: {
      width: 330,
    },
  },
  input: {
    display: 'none',
  },
  imageWrapper: {
    flexBasis: '50%',
  },
}));

const ImagesGallery = ({
  disableClick,
  onImageSelect,
  selected,
  onAddToCart,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector((s) => s.cart.items);
  const gallery = useSelector((s) => s.app.gallery);
  const [progress, setProgress] = React.useState({ total: 0, completed: 0 });

  const onFileSelect = async (e) => {
    const { files } = e.target;
    const allFiles = Array.from(files);
    console.log(`Load images button click - ${allFiles.length} files selected`);
    Sentry.captureMessage(`Load Images into gallery button click`);
    setProgress({ total: allFiles.length, completed: 0 });
    for (const file of allFiles) {
      const hash = getFileHash(file);
      const thumb = await genThumb(file, {
        skipResolution: 16000,
      });
      setInCache(hash, thumb);
      dispatch(addToGallery({ [hash]: file }));
      setProgress((p) => ({ ...p, completed: p.completed + 1 }));
    }
  };

  const isInCart = (img) => {
    const cartQty = items.filter((x) =>
      x.product.template.nodes.some((n) => n.image === img)
    ).length;
    return cartQty;
  };

  const showProgress =
    progress.total > 0 && progress.completed < progress.total;

  return (
    <Box className={classes.root}>
      {showProgress && (
        <Snackbar open autoHideDuration={6000}>
          <Alert severity="info" variant="filled" elevation={6}>
            {progress.completed}/{progress.total} images processed
          </Alert>
        </Snackbar>
      )}
      <Box mb={1}>
        <label htmlFor="gallery-button-file">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component="span"
          >
            <Box display="block">
              <Typography align="center" variant="h6">
                Load Images
              </Typography>
              <Typography align="center" variant="caption" component="p">
                Click to load multiple images into a gallery
              </Typography>
            </Box>
          </Button>
        </label>
        <input
          multiple
          onChange={onFileSelect}
          accept="image/png, image/jpeg"
          className={classes.input}
          id="gallery-button-file"
          type="file"
        />
        {Object.keys(gallery).length ? (
          <Typography paragraph align="center" variant="caption" component="p">
            Click an image to load it into the crop box (or drag and drop) make
            sure a size is selected on the left first
          </Typography>
        ) : null}
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="center" mb={1}>
        {Object.keys(gallery).map((i) => (
          <LazyLoad
            key={i}
            once
            overflow
            height={100}
            className={classes.imageWrapper}
            placeholder={<Placeholder />}
          >
            <Thumbnail
              disableClick={disableClick}
              isInCart={isInCart(gallery[i])}
              image={gallery[i]}
              onClick={onImageSelect}
              selected={selected}
              onAddToCart={onAddToCart}
            />
          </LazyLoad>
        ))}
      </Box>
      {Object.keys(gallery).length ? (
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => dispatch(clearGallery())}
        >
          Clear Gallery
        </Button>
      ) : null}
    </Box>
  );
};

export default ImagesGallery;
