import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import walletImageTop from 'images/wallets/wallet-photos-and-prints.webp';
import { Box as MuiBox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ButtonBase from '@material-ui/core/ButtonBase';

const devEnvironment = process.env.RAZZLE_APP_STATUS;

const useStyles = makeStyles((theme) => ({
  responsiveImage: {
    width: '100%',
    height: 'auto',
    maxWidth: '1200px', // Adjust this value as needed
  },
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'box-shadow 0.2s ease-in-out',
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  cardMedia: {
    paddingTop: '65%', // Controls Aspect Ratio
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // This will push the button to the bottom
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  productName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  description: {
    flexGrow: 1, // This will make the description expand
    marginBottom: theme.spacing(1),
  },
  priceInfo: {
    marginTop: 'auto', // This will push the price info to the bottom of productInfo
  },
  cardLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    height: '100%',
  },
  orderButton: {
    marginTop: theme.spacing(2), // Add some space above the button
  },
  gridContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2), // Add horizontal padding on xs screens
    },
  },
  gridItem: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0), // Reduce vertical padding on xs screens
    },
  },
  h4: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0),
  },
}));

export default function Wallets() {
  const classes = useStyles();

  const products = [
    {
      id: 1,
      name: 'Die Cut Wallet Photos - Set of 8',
      description:
        '8 Wallet size photos with rounded corners. Die cut wallets are approximately 2.25x3.25 inches.',
      price: 4.0,
      qtyPrice: 3.0,
      imageUrl: '/wallets/wallet-photos-die-cut.webp',
      url: '/editor/photo-prints/h5oNJo5HJ',
    },
    {
      id: 11,
      name: 'Die Cut Wallets - Set of 8 w/Text Vertical',
      description:
        '8 Wallet size photos with rounded corners and 2 lines of text in the corner.',
      price: 4.0,
      qtyPrice: 3.0,
      imageUrl: '/wallets/8-wallet-photos-with-text-die-cut-vertical.jpg',
      url: '/editor/photo-prints/rxYvJVgKK',
    },
    {
      id: 111,
      name: 'Die Cut Wallets - Set of 8 w/Text Horizontal',
      description:
        '8 Wallet size photos with rounded corners and 2 lines of text in the corner.',
      price: 4.0,
      qtyPrice: 3.0,
      imageUrl: '/wallets/8-wallet-photos-with-text-die-cut.jpg',
      url: '/editor/photo-prints/ra01E8alb',
    },
    {
      id: 1112,
      name: 'Die Cut Wallets - Set of 8 Unique Wallets',
      description:
        '8 Wallet size photos with rounded corners. Each wallet can be a different photo.',
      price: 4.0,
      qtyPrice: 3.0,
      imageUrl: '/wallets/8-wallets-unique-die-cut.jpg',
      url: '/editor/photo-prints/GHDfTTsta',
    },

    {
      id: 24,
      name: '4 Wallets, UnCut',
      description:
        'Sheet of 4 wallets, you will need to cut them apart. These are 2.5x3.5 inches.',
      price: 1.75,
      qtyPrice: 1.0,
      imageUrl: '/wallets/4-wallet-photos.jpg',
      url: '/editor/photo-prints/GFCpS6hK7',
    },
    {
      id: 23,
      name: '16 Mini Wallets, UnCut',
      description:
        'Sheet of 16 mini wallets, you will need to cut them apart. Mini wallets are 1.75 x 2.5 inches.',
      price: 4.0,
      qtyPrice: 3.0,
      imageUrl: '/wallets/16-mini-wallet-photos.jpg',
      url: '/editor/photo-prints/2eOJFZokw',
    },
    {
      id: 22,
      name: '5x7',
      description: '5x7 photo print, perfect in a small frame on a desk.',
      price: 1.75,
      qtyPrice: 1.0,
      imageUrl: '/wallets/5x7-photo-print.jpg',
      url: '/editor/photo-prints/5IetAqdJw',
    },
    {
      id: 223,
      name: '8x10',
      description: '8x10 photo print, perfect in a frame on the wall.',
      price: 5.5,
      qtyPrice: 4.0,
      imageUrl: '/wallets/8x10-photo-print.jpg',
      url: '/editor/photo-prints/hHVDYtPG4',
    },
    {
      id: 224,
      name: '10x13',
      description: '10x13 photo print',
      price: 7,
      qtyPrice: 5,
      imageUrl: '/wallets/10x13-photo-print.jpg',
      url: '/editor/photo-prints/jJmPRsrP5',
    },
  ];

  return (
    <Box marginTop={2} marginBottom={3}>
      <Helmet title="Order Wallet Photos">
        <meta
          name="description"
          content="Order wallet photos and other size prints with our easy to use online photo ordering system."
        />
      </Helmet>
      <Box textAlign="center" mb={1} display="flex" justifyContent="center">
        <Box
          component="img"
          src={walletImageTop}
          alt="Wallet Photo Print Example"
          className={classes.responsiveImage}
        />
      </Box>
      <Container fixed className={classes.container}>
        <Typography variant="h1">
          Order Wallet Photos and Prints with our Easy to Use Online Photo
          Ordering System
        </Typography>
        <Typography>
          Need to order wallet photos? we have a few different options for
          wallet photos including die cut wallets (wallet photos with rounded
          corners), wallet photos with text and mini wallets.
        </Typography>
        <Typography paragraph>
          We also offer a few normal size prints including 5x7, 8x10 and 10x13.
        </Typography>
        <Typography paragraph>
          All prints are on Luster Fuji Professional Photo Paper - Type PDN.
        </Typography>
        <div className={classes.root}>
          <Typography variant="h4" className={classes.h4}>
            Order Wallet Photos and Regular Prints
          </Typography>
          <Typography>
            Click a product to start ordering using our Simple Order System.
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className={classes.gridContainer}
          >
            {products.map((product) => (
              <Grid
                item
                key={product.id}
                xs={12}
                sm={6}
                md={4}
                className={classes.gridItem}
              >
                <Link to={product.url} className={classes.cardLink}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={product.imageUrl}
                      title={product.name}
                    />
                    <CardContent className={classes.cardContent}>
                      <div className={classes.productInfo}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="h2"
                          className={classes.productName}
                          title={product.name}
                        >
                          {product.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.description}
                        >
                          {product?.description}
                        </Typography>
                        <div className={classes.priceInfo}>
                          <Typography variant="body1">
                            ${product.price.toFixed(2)} each
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            10 or more ${product.qtyPrice.toFixed(2)} each
                          </Typography>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.orderButton}
                      >
                        Order Now
                      </Button>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
        <Typography variant="h3">Looking for more sizes?</Typography>
        <Typography>
          This site features a simplified ordering system dedicated to wallet
          photos. If you are looking for additional sizes check out our main lab{' '}
          <a href="https://prolabprints.com" target="_blank">
            ProLabPrints.com
          </a>
          . If you need small prints to fit inside a locket check out our{' '}
          <a href="https://locketprints.com" target="_blank">
            LocketPrints.com site dedicated to locket photos
          </a>
          .
        </Typography>
      </Container>
    </Box>
  );
}
