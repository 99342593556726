import { Button, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Notes from '../../components/helpNotes';

const Help = () => {
  const lab = useSelector((s) => s.lab);
  return (
    <Box marginTop={2}>
      <Helmet title={`${lab.lab_name} - Help`}>
        <meta
          name="description"
          content="having trouble ordering? See some help topics here or let us know what is wrong."
        />
      </Helmet>
      <Container>
        <Typography variant="h1">Ordering System Help</Typography>
        <Typography paragraph>
          If you are having trouble with our ordering system please let us know
          and we'd be happy to help!
        </Typography>
        <Typography paragraph>
          Phone: {lab.lab_phone}
          <br /> Email: <a href={`mailto:${lab.lab_email}`}>{lab.lab_email}</a>
        </Typography>
        <Typography variant="h2">
          Common Questions and Tips for Ordering
        </Typography>
        <Notes />
        {lab.globalOptions.require_userid && (
          <Box marginTop={3}>
            <Typography variant="h2">Info for Resellers</Typography>
            <Typography paragraph>
              If you are a reseller please make sure we have a current resale
              certificate on file and then make sure you have entered your
              Account Number at checkout. This is required, if you do not do
              this you will be charged tax for any orders shipped to states we
              collect tax in.
            </Typography>
            <Typography paragraph>
              When everything is setup properly, on the payment page you will
              see that the tax is zero and it should say Exempt.
            </Typography>
            <Typography paragraph>
              If you are not sure what your Account Number is please contact us
              and we will be happy to help you. If you changed computers or
              browsers recently you may have had your number entered on prior
              orders, check your email for old orders and see if your account
              number is in there. You also would have received a welcome email
              that included your account number when you first sent us an order.
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Help;
