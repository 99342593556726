import { Box, Container } from '@material-ui/core';
import OrderSummary from 'containers/checkout/components/OrderSummary';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

export default function Summary({ history, location }) {
  const { state } = location;
  const lab = useSelector((s) => s.lab);

  useEffect(() => {
    if (!location.state) {
      history.replace('/');
    }
  }, []);

  if (!state) return null;

  const { order } = state;

  return (
    <Container>
      <Helmet title={`Order Summary`} />
      <Box my={4}>
        <OrderSummary order={order} />
      </Box>
    </Container>
  );
}
