export const ADD_TO_GALLERY = 'ADD_TO_GALLERY';
export const CLEAR_GALLERY = 'CLEAR_GALLERY';

export const addToGallery = (items) => ({
  type: ADD_TO_GALLERY,
  payload: items,
});

export const clearGallery = () => ({
  type: CLEAR_GALLERY,
});
