import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { money } from 'utils';
import { getFromCache } from 'utils/img-cache';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: '100%',
    borderCollapse: 'separate',
    borderLeft: '1px solid #dadada',
    '& th, td': {
      textAlign: 'center',
      padding: '8px',
      borderBottom: '1px solid #dadada',
      borderRight: '1px solid #dadada',
    },
    '& tr': {
      height: '50px',
    },
    '& tr:nth-child(odd)': { backgroundColor: '#f2f2f2' },
    '& th': { backgroundColor: '#fff', borderTop: '1px solid #dadada' },
  },
  image: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 100,
    display: 'block',
    '& img': {
      maxHeight: 60,
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
  test: {
    '@media print': {
      display: 'none',
    },
  },
}));

const OrderSummary = ({ order, onClose }) => {
  const classes = useStyles();
  const {
    orderId,
    customer,
    shippingAddress,
    lineItemsTotal,
    optionsTotal,
    orderTotal,
    shippingTotal,
    taxTotal,
    taxRate,
    grandTotal,
    orderNotes,
    paymentMethod,
    imagesCount,
    orderOptions,
    shippingMethod,
    currency,
    lineItems,
  } = order;
  return (
    <Box>
      <Typography gutterBottom variant="h2">
        Order # {orderId}
      </Typography>

      <Box mb={2}>
        <Typography>
          {customer.firstName} {customer.lastName}
        </Typography>
        <Typography>{customer.phone}</Typography>
        <Typography>{customer.email}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          Shipping Method: {shippingMethod.shippingmethod}
        </Typography>
        <Typography variant="caption">{shippingMethod.description}</Typography>
        {shippingMethod.shiptype !== 'pickup' && (
          <>
            <Typography>Ship To:</Typography>
            <Typography>{shippingAddress.address1}</Typography>
            <Typography>{shippingAddress.address2}</Typography>
            <Typography>
              {shippingAddress.city}, {shippingAddress.state}{' '}
              {shippingAddress.zip}
            </Typography>
          </>
        )}
        <Typography paragraph variant="caption">
          *Please note below we only show the first file for each product in the
          order and the thumbnail will only show under certain circumstances!
          <br /> If there is a thumbnail it does not show cropping.
          <br />
          This summary is meant to be a simple recap of your order and does not
          show all order details like options.
        </Typography>
      </Box>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>File*</th>
            <th>Product</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map((item, idx) => {
            const imgNode = item.product.template.nodes.find((i) => i.image);
            const cachedImg = imgNode && getFromCache(imgNode.image.hash);
            return (
              <tr key={idx}>
                <td>
                  {cachedImg && (
                    <img className={classes.image} src={cachedImg} />
                  )}
                  <Typography variant="caption">
                    {imgNode.image.name}
                  </Typography>
                </td>
                <td>
                  {item.product.size}
                  <br /> {item.catalog.cat_name}
                </td>
                <td>{money(item.rate, currency)} each</td>
                <td>{item.quantity}</td>
                <td>{money(item.totalPrice, currency)}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="4">Subtotal</td>
            <td>{money(orderTotal, currency)}</td>
          </tr>
          <tr>
            <td colSpan="4">Shipping</td>
            <td>{money(shippingTotal, currency)}</td>
          </tr>
          <tr>
            <td colSpan="4">Tax at ${taxRate * 100}%:</td>
            <td>{money(taxTotal, currency)}</td>
          </tr>
          <tr>
            <td colSpan="4">Total:</td>
            <td>{money(grandTotal, currency)}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default OrderSummary;
