import React, { useState } from 'react';
import {
  CardNumberElement,
  useElements,
  useStripe,
  Elements,
} from '@stripe/react-stripe-js';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from 'components/Button';
import CardDetails from './CardDetails';
import { makeStyles } from '@material-ui/core';
import StripeLogo from '../../../images/blurple.svg';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles((theme) => ({
  btnxl: {},
  btnLine2: {
    fontSize: 'small',
  },
  helpText: {
    paddingTop: 16,
  },
  bold: { fontWeight: 500 },
}));

const ErrorMessage = ({ children }) => (
  <Box marginTop={1} style={{ color: 'red' }}>
    {children}
  </Box>
);

const CheckoutForm = ({ onToken }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      Sentry.captureMessage(
        'Stripe.js has not loaded yet - inside complete order button click'
      );
      return;
    }

    if (error) {
      setError(error.message);
      Sentry.captureException(error);
      return;
    }

    if (event.currentTarget.value === 'payWith') {
      return onToken('');
    }

    const cardElement = elements.getElement(CardNumberElement);
    const payload = await stripe.createToken(cardElement, {});

    if (payload.error) {
      setError(payload.error);
      Sentry.captureException(payload.error);
    } else {
      Sentry.captureMessage(`SOSV2 payment submitted via Stripe`);
      return onToken(payload.token.id);
    }
  };

  return (
    <div>
      <img
        src={StripeLogo}
        alt="Stripe Logo"
        style={{
          float: 'right',
          maxWidth: 140,
          marginBottom: '2rem',
        }}
      />
      <Typography className={classes.bold}>Card Details:</Typography>

      <CardDetails setFormComplete={setCardComplete} />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <Typography variant="caption" component="p" className={classes.helpText}>
        If you can't enter anything into the form above you might have a browser
        extension blocking our Stripe integration, try disabling it or use the
        Other / Card on File option.
      </Typography>
      <Box marginTop={2}>
        <Button
          fullWidth
          className={classes.btnxl}
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="secondary"
          disabled={!cardComplete}
        >
          <Typography variant="h6">
            Complete Order <br />
            <span className={classes.btnLine2}>
              Next we'll show your image upload progress
            </span>
          </Typography>
        </Button>
      </Box>
    </div>
  );
};

export default CheckoutForm;
