import React, { useState, useEffect } from 'react';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from 'components/Button';
import { money } from 'utils';
import { getTirePrice } from 'utils/priceHelpers';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  options: {
    margin: '10px 0',
  },
  optionName: {
    marginBottom: '8px',
    fontWeight: 500,
  },
});

const OptionsModal = ({ onClose, handleSubmit, item, currency }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(item.options || {});

  const onSave = () => {
    handleSubmit(selected);
  };

  const getOptionPrice = (option, choicePrice) => {
    const tirePrice = getTirePrice(choicePrice, item.quantity);

    return tirePrice;
  };

  const selectOption = (val, cat) => {
    if (!val) {
      delete selected[cat];
    } else {
      selected[cat] = val;
    }
    setSelected({ ...selected });
  };

  const options = item.product.options?.map((option) => {
    const choices = option.choices.map((choice) => {
      const price = getOptionPrice(option, choice.price);
      const label = choice.label + ' - ' + money(price, currency);

      return (
        <option key={choice.id} value={choice.id}>
          {label}
        </option>
      );
    });

    return (
      <FormControl
        fullWidth
        variant="outlined"
        key={option.id}
        className={classes.options}
      >
        <Typography className={classes.optionName}>
          {option.description}:
        </Typography>

        <Select
          native
          onChange={(e) => selectOption(e.target.value, option.id)}
          value={selected[option.id] || ''}
        >
          <option value="">Select an Option</option>
          {choices}
        </Select>
      </FormControl>
    );
  });

  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle>Available Options</DialogTitle>
      <DialogContent dividers>{options}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelected({})}
        >
          Clear Options
        </Button>
        <Button variant="contained" color="secondary" onClick={onSave}>
          Save and Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptionsModal;
