import React from 'react';
import { ErrorMessage, Field } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import RawTextField from '../Input';
import ReactSelect from '../Select';
import RawRadio from './FormikRadioInput';
import RawCheckbox from '../Checkbox';
import PhoneInput from '../PhoneNumberInput';
import RawRadioGroup from '../Radio';

function enhanceWithFormikControls(Component, defaultValue) {
  ComponentWithFormik.propTypes = {
    ...Field.propTypes,
    ...Component.propTypes,
  };

  function ComponentWithFormik({ field, onChange, ...props }) {
    return (
      <Component
        name={field.name}
        value={field.value || defaultValue}
        onChange={(e) => {
          field.onChange(e);
          if (onChange) onChange(e);
        }}
        onBlur={field.onBlur}
        {...props}
      />
    );
  }

  return ComponentWithFormik;
}

function enhanceWithFormikField(Component, defaultValue) {
  ComponentWithFormik.propTypes = {
    ...Field.propTypes,
    ...Component.propTypes,
  };
  const WithFormikControls = enhanceWithFormikControls(Component, defaultValue);

  function ComponentWithFormik(props) {
    const { name } = props;
    return (
      <>
        <Field component={WithFormikControls} {...props} />
        <ErrorMessage
          name={name}
          component={FormHelperText}
          style={{ color: 'red' }}
        />
      </>
    );
  }

  return ComponentWithFormik;
}

export const Select = enhanceWithFormikField(ReactSelect, null);
export const TextField = enhanceWithFormikField(RawTextField, '');
export const PhoneNumberInput = enhanceWithFormikField(PhoneInput, '');
export const Radio = enhanceWithFormikField(RawRadio, false);
export const RadioGroup = enhanceWithFormikField(RawRadioGroup, '');
export const Checkbox = enhanceWithFormikField(RawCheckbox, false);
