import { useRef, useContext, useState, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import ProductCard from 'components/ProductCard';
import { useSelector } from 'react-redux';
import { isValidTemplate } from 'utils';

const useStyles = makeStyles({
  products: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  file: {
    visibility: 'hidden',
  },
});

const ChooseProduct = ({
  match: {
    params: { category },
  },
  history,
}) => {
  const classes = useStyles();
  const catalogs = useSelector((s) => s.catalogs);
  const catalog = useMemo(
    () => catalogs.find((c) => c.cat_id === category),
    [category]
  );

  const onTemplateClick = (tmpl) => (e) => {
    history.push(`/editor/${category}/${tmpl.id}`);
  };

  return (
    <Box my={2}>
      <Helmet title={`Choose product - ${catalog.cat_name}`}>
        <meta name="description" content="Choose product to order." />
      </Helmet>
      <Container>
        <Typography align="center" variant="h1">
          {catalog.cat_name}
        </Typography>

        <Typography paragraph align="center">
          {catalog.cat_description}
        </Typography>
        <Box className={classes.products}>
          {catalog.products.filter(isValidTemplate).map((i) => (
            <ProductCard
              key={i.id}
              product={i}
              showPrice={catalog.cat_show_prices}
              onSelect={onTemplateClick(i)}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default ChooseProduct;
