import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { money } from 'utils';
import { getTirePrice } from 'utils/priceHelpers';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

const useStyles = makeStyles((theme) => ({
  template: {
    display: 'flex',
    flexDirection: 'column',
    width: '120px',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '8px 0',
    margin: '6px 12px',
    textAlign: 'center',
    border: '1px solid transparent',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      borderColor: 'silver',
    },
    '& img': {
      border: '1px solid #afafaf',
      maxHeight: '90px',
      maxWidth: '100px',
      objectFit: 'contain',
    },
    '&.selected': {
      backgroundColor: '#f5f5f5',
      borderColor: '#848484',
    },
  },
  noTemplate: {
    display: 'flex',
    flexDirection: 'column',
    width: '120px',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '12px 0',
    margin: '12px 12px',
    textAlign: 'center',
    border: '1px solid #ddd',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      borderColor: 'silver',
    },
    '&.selected': {
      backgroundColor: '#f5f5f5',
      borderColor: '#848484',
    },
  },
  productName: {
    paddingTop: '6px',
  },
  productPrice: {},
  sale: {
    color: 'green',
  },
  badTemplate: {
    color: 'red',
  },
}));

const devEnvironment = process.env.RAZZLE_APP_STATUS;

const ProductCard = ({ product, isSelected, onSelect, showPrice }) => {
  if (devEnvironment != 'testing' && product.hidden) return null;
  const classes = useStyles();
  const lineItems = useSelector((s) => s.cart.items);
  const price = useSelector((s) => s.cart.price);
  const currency = useSelector((s) => s.lab.globalOptions.currency);
  const itemPriceList = useSelector((s) => s.lab.globalOptions.price_list);
  const svgHeight = Math.round((product.height / product.width) * 80);
  const svgWidth = Math.round((product.width / product.height) * 80);
  const badTemplate =
    product.template.nodes[0].height > product.height ||
    product.template.nodes[0].width > product.width;

  useEffect(() => {
    if (isSelected) {
      const el = document.getElementById(`tmpl-${product.id}`);
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isSelected]);

  const onClick = () => {
    if (onSelect) {
      onSelect(product);
    }
  };

  const itemPrice = useMemo(() => {
    const cartProduct = lineItems.find((i) => i.product.id === product.id);
    if (cartProduct && price) {
      const item = price.lineItems[cartProduct.id];
      if (item) {
        return money(item.rate, currency);
      }
    } else {
      // master price in use?
      const prices = product.priceId
        ? itemPriceList.find((i) => i.id === product.priceId).price
        : product.price;
      return money(getTirePrice(prices, 1), currency);
    }
  }, [price]);

  return (
    <Box
      id={`tmpl-${product.id}`}
      className={`${product.hidden && classes.badTemplate} ${cx(
        product.width || product.preview
          ? classes.template
          : classes.noTemplate,
        {
          selected: isSelected,
        }
      )}`}
      onClick={onClick}
    >
      {product.preview ? (
        <img src={product.preview} />
      ) : product.width <= product.height ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${svgWidth} 80`}
          width={svgWidth}
          height="80px"
        >
          <rect
            x="0"
            y="0"
            width={svgWidth}
            height="80px"
            fill="white"
            stroke="#bbbbbb"
            strokeWidth="3"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 80 ${svgHeight}`}
          width="80px"
          height={svgHeight}
        >
          <rect
            x="0"
            y="0"
            width="80px"
            height={svgHeight}
            fill="white"
            stroke="#bbbbbb"
            strokeWidth="3"
          />
        </svg>
      )}
      <Typography className={classes.productName} variant="subtitle2">
        {product.size} {product.hidden && '(Hidden)'}
      </Typography>
      {product.onSale && (
        <Typography className={classes.sale}>
          <LoyaltyIcon style={{ fontSize: 14 }} /> Sale
        </Typography>
      )}
      {showPrice && (
        <Typography className={classes.productPrice} variant="caption">
          {itemPrice}
        </Typography>
      )}
      {badTemplate && (
        <Typography className={classes.badTemplate} variant="caption">
          Bad template! Image node larger than template.
        </Typography>
      )}
    </Box>
  );
};

export default ProductCard;
