const CURRENCIES = {
  'Dollars-$': '$',
  'GBP-£': '£',
  'EUR-€': '€'
};

export default CURRENCIES;

export const CURRENCY_CODES = {
  'Dollars-$': 'usd',
  'GBP-£': 'gbp',
  'EUR-€': 'eur'
}