import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import * as Sentry from '@sentry/react';
import Checkbox from 'components/form/Checkbox';
import RotateSettings from 'components/RotateSettings';
import CartOptions from './components/CartOptions';
import ProductCard from 'components/ProductCard';
import Canvas from 'components/editor/Canvas';
import {
  applyCanvasRotation,
  getFileHash,
  getRotationFromImage,
  getZoom,
  isValidTemplate,
  shortId,
  parseLinkedNodes,
  parseTextTemplate,
  getNextAlphabet,
  getFileNameParts,
  getSiblingNodes,
} from 'utils';
import Hidden from '@material-ui/core/Hidden';
import NodesSettings from '../../components/editor/NodesSettings';
import ImagesGallery from 'components/Gallery/ImagesGallery';
import CatalogCard from './components/CatalogCard';
import OptionsModal from '../cart/components/OptionsModal';
import {
  addToCart,
  calculatePrice,
  updateItem,
  uploadItem,
} from 'store/actions/cart';
import debounce from 'lodash/debounce';
import useKeyPress from 'hooks/useKeyPress';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: 340,
    flexShrink: 0,
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: 180,
    },
  },
  drawerPaper: {
    width: 340,
    position: 'absolute',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('lg')]: {
      width: 180,
    },
  },
  content: {
    flexGrow: 1,
    paddingBottom: 10,
    backgroundColor: '#fafafa',
    // padding: theme.spacing(3),
    minHeight: 'calc(100vh - 104px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
  },
  templatesList: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  settingsPane: {
    width: 250,
    flexShrink: 0,
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      width: 300,
    },
    [theme.breakpoints.up('xl')]: {
      width: 330,
    },
  },
  settingsPaper: {
    position: 'absolute',
    top: 0,
  },
  mobileSettingsPaper: {
    width: 300,
  },
  input: {
    display: 'none',
  },
  select: {
    padding: '6px 12px',
  },
  option: {
    padding: 6,
  },
  highlight: {
    color: 'blue',
  },
  breadcrumb: {
    padding: '6px 12px',
    borderBottom: '1px solid  #dcdcdc',
    backgroundColor: 'white',
    marginBottom: '16px',
  },
}));

// assign node id to older products
const assignNodeIds = (nodes) => {
  let id;
  return nodes.map((node) => {
    if (!node.id) {
      id = getNextAlphabet(id);
      node.id = id;
    } else if (node.id > id) {
      id = node.id;
    }
    return node;
  });
};

const Editor = ({
  match: {
    params: { category, id, itemId },
  },
  history,
}) => {
  const catalogs = useSelector((s) => s.catalogs);
  const lab = useSelector((s) => s.lab);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const cart = useSelector((s) => s.cart);
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [showOptions, setShowOptions] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [productOptions, setProductOptions] = useState();
  const [selectedNodeId, setSelectedNodeId] = useState('A'); // assuming first node is always image
  const [highlight, setHighlight] = useState(false);
  // Add these new state variables
  // const [debugInfo, setDebugInfo] = useState({});
  const cropperRefs = useRef({});
  const isMultiImageTemplate =
    product?.template?.nodes?.filter((i) => i.type === 'image').length > 1;
  const isImagesConfigured = product?.template?.nodes
    ?.filter((i) => i.type === 'image')
    .every((i) => i.image);
  const emptyNodesOk =
    product?.template?.nodes
      ?.filter((i) => i.type === 'image')
      .some((i) => i.image) && product?.allowEmptyNodes;
  const linkedNodes = useMemo(
    () => parseLinkedNodes(product?.template?.linkedNodes),
    [product?.template.linkedNodes]
  );
  const selectedNode = useMemo(() => {
    return product?.template.nodes.find((i) => i.id === selectedNodeId);
  }, [selectedNodeId, product]);

  const catalog = useMemo(
    () => catalogs.find((c) => c.cat_id === category),
    [category]
  );

  useEffect(() => {
    setProductOptions();
    cropperRefs.current = {};
    if (!id) {
      setProduct();
      return;
    }
    if (itemId) {
      //editing item from cart
      console.log('editing item from cart');
      const item = cart.items.find((i) => i.id === itemId);
      if (!item) return history.push('/');
      const tmpl = group.products.find((i) => i.id === id);
      setQuantity(item.quantity);
      setProductOptions(item.options);
      //in editor with original product
      if (tmpl.id === item.product.id) {
        const zoomRatio = getZoom(item.product);
        const oldZoomRatio = item.product.zoomRatio;
        // check if browser window different now then when ordered
        // if so delete crop info and reset? or just reset zoom apparently. No need for if?
        if (zoomRatio !== oldZoomRatio) {
          Sentry.captureMessage(
            'editing in cart and browser size is in a different breakpoint than when ordering!'
          );
          item.product.zoomRatio = getZoom(item.product);
        }
        setProduct(cloneDeep(item.product));
        // setExpandAccordion(
        //   tmpl.template.nodes.some((item) => item.type === 'text')
        // );
      } else {
        // in editor from cart but changed product? probably can't do this now - product list hidden if editing
        const zoomRatio = (tmpl.zoomRatio = getZoom(tmpl));
        const newTmpl = cloneDeep(tmpl);
        newTmpl.zoomRatio = zoomRatio;
        newTmpl.key = item.product.key;
        newTmpl.template.nodes.forEach((node, idx) => {
          const prevNode = item.product.template.nodes[idx];
          if (prevNode) {
            if (prevNode.image) {
              node.image = prevNode.image;
              setTimeout(() => onImageSelect(node.image, idx), 0);
            }
          }
        });
        setProduct(newTmpl);
        // setExpandAccordion(
        //   newTmpl.template.nodes.some((item) => item.type === 'text')
        // );
      }
    } else {
      if (catalog) {
        const _product = catalog.products.find((i) => i.id === id);
        const newProduct = cloneDeep(_product);
        assignNodeIds(newProduct.template.nodes);
        const zoomRatio = getZoom(newProduct);
        newProduct.zoomRatio = zoomRatio;
        if (!product) {
          setProduct(newProduct);
        } else {
          // only copy first node image. #189
          const firstNode = newProduct.template.nodes.find(
            (i) => i.type === 'image'
          );
          const prevFirstNode = product.template.nodes.find(
            (i) => i.type === 'image'
          );
          if (prevFirstNode && prevFirstNode.image) {
            firstNode.image = prevFirstNode.image;
            firstNode.rotate = undefined;

            const linkedNodes = parseLinkedNodes(
              newProduct.template.linkedNodes
            );
            const otherNodes = getSiblingNodes(firstNode.id, linkedNodes);
            if (otherNodes) {
              otherNodes.forEach((id) => {
                const node = newProduct.template.nodes.find((n) => n.id === id);
                node.image = firstNode.image;
                node.rotate = undefined;
              });
            }
          }
          setProduct(newProduct);
          initProductChange(newProduct);
        }
      } else {
        history.push('/');
      }
    }
  }, [id, itemId]);
  // -- danger on mobile, too easy to pinch by accident and kicks them out.
  // check if new-zoomratio === old-zoomratio and only do something if not
  useEffect(() => {
    const onResize = debounce(() => {
      if (product) {
        // check if zoomratio is same, if so exit
        const newZoom = getZoom(product);
        if (newZoom === product.zoomRatio) {
          console.log('browser size change but zoomRatio same, exiting');
          return;
        }
        console.log(
          'product, newZoom not the same:>> ',
          product.zoomRatio,
          newZoom
        );
        if (!itemId) {
          Sentry.captureMessage(
            'Browser resized while in editor, zoomRatio different, resetting editor'
          );
          alert(
            'Uh Oh! Your screen size has changed, we are going to reset this product to prevent cropping issues.'
          );
          resetEditor();
          return;
          // almost working except for nodes with rotation are screwed up
          //updateCanvasSize(newZoom);
          //return;
        }
        // must be editing from cart, do nothing? should be rare case?
        // could kick back to cart with snack?
        Sentry.captureMessage(
          'Browser resized while editing? zoomRatio different, probable bad crop'
        );
        alert(
          'Uh Oh! Your screen size has changed, this will cause a cropping issue so we are going to send you back to the cart. Please try again. It is important that you keep the screen size the same once you click the edit button from the cart.'
        );
        history.push(`/cart`);
      }
    }, 50);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [product]);

  useEffect(() => {
    setHighlight(true);
    const timer = setTimeout(() => setHighlight(false), 1000);
    return () => clearTimeout(timer);
  }, [selectedNodeId]);

  useKeyPress(
    ['ArrowRight', 'ArrowLeft'],
    (e) => {
      if (!itemId || cart.items.length === 1) return;
      const currentIndex = cart.items.findIndex((i) => i.id === itemId);
      let nextItem;
      switch (e.key) {
        case 'ArrowRight': {
          Sentry.captureMessage('Right Arrow key pressed');
          if (currentIndex === cart.items.length - 1) {
            nextItem = cart.items[0];
          } else {
            nextItem = cart.items[currentIndex + 1];
          }
          break;
        }
        case 'ArrowLeft': {
          Sentry.captureMessage('Left Arrow key pressed');
          if (currentIndex === 0) {
            nextItem = cart.items[cart.items.length - 1];
          } else {
            nextItem = cart.items[currentIndex - 1];
          }
          break;
        }
      }
      const nextIndex = cart.items.indexOf(nextItem);
      if (nextIndex === cart.items.length - 1) {
        enqueueSnackbar('This is the last image in your cart.', {
          variant: 'info',
          autoHideDuration: 5000,
        });
      }
      onAddToCart(true);
      resetEditor();
      history.push(
        `/editor/${nextItem.category}/${nextItem.product.id}/${nextItem.id}`
      );
    },
    null,
    200
  );
  // useeffect to set debug info
  // also needs usestate above and debug function below
  // useEffect(() => {
  //   if (
  //     product &&
  //     selectedNode &&
  //     cropperRefs.current[selectedNode.id]?.current
  //   ) {
  //     const cropper = cropperRefs.current[selectedNode.id].current.cropper;
  //     const updateDebugInfo = debounce(() => {
  //       const imageData = cropper.getImageData();
  //       const containerData = cropper.getContainerData();
  //       const canvasData = cropper.getCanvasData();

  //       setDebugInfo({
  //         zoomRatio: product.zoomRatio,
  //         imageData: imageData,
  //         containerData: containerData,
  //         canvasData: canvasData,
  //         viewMode: cropper.options.viewMode,
  //         fitImage: selectedNode.fitImage,
  //         rotation: imageData.rotate || 0,
  //       });
  //     }, 100);

  //     updateDebugInfo(); // Initial update

  //     cropper.element.addEventListener('crop', updateDebugInfo);
  //     cropper.element.addEventListener('zoom', updateDebugInfo);

  //     return () => {
  //       cropper.element.removeEventListener('crop', updateDebugInfo);
  //       cropper.element.removeEventListener('zoom', updateDebugInfo);
  //     };
  //   }
  // }, [product, selectedNode]);

  const updateCanvasSize = (newZoom) => {
    setProduct((prevProduct) => {
      const updatedProduct = cloneDeep(prevProduct);
      updatedProduct.zoomRatio = newZoom;
      //could do something here to check if a node has rotation and then not set the crop data?
      // updatedProduct.template.nodes.forEach((node) => {
      //  if (node.type === 'image' && node.image) {
      // if (node.rotate === 9) {
      //   const cropperRef = cropperRefs.current[node.id];
      //   if (cropperRef && cropperRef.current) {
      //     const cropper = cropperRef.current.cropper;
      //     console.log('cropper', cropper);
      //     setTimeout(() => {
      //       cropper.setData({
      //         left: 0,
      //         top: 0,
      //         width: 578,
      //         height: 432,
      //       });
      //     }, 0);
      //     updateNode(node, true);
      //   }
      // }
      //}
      // });
      return updatedProduct;
    });
  };

  // Update the renderDebugInfo function
  // const renderDebugInfo = () => (
  //   <Box mt={2} p={2} border={1} borderColor="grey.300">
  //     <Typography variant="h6">Debug Info:</Typography>
  //     <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
  //       {JSON.stringify(debugInfo, null, 2)}
  //     </pre>
  //   </Box>
  // );
  const initProductChange = async (newProduct) => {
    //user changed product.. rotate canvas if required only if it is single image template
    const imgNode = newProduct.template.nodes.find((n) => n.type === 'image');
    if (imgNode.image && !isMultiImageTemplate) {
      if (
        newProduct.template.nodes.length === 1 &&
        !newProduct.template.overlay
      ) {
        return getRotationFromImage(imgNode.image, imgNode).then((rotate) => {
          if (rotate) {
            console.log('apply canvas rotation');
            const newTemplate = applyCanvasRotation(newProduct);
            setProduct(cloneDeep(newTemplate));
          }
        });
      }
    }
  };

  const onProductSelect = (tmpl) => {
    if (itemId) {
      history.push(`/editor/${category}/${tmpl.id}/${itemId}`);
    } else {
      history.push(`/editor/${category}/${tmpl.id}`);
    }
  };

  const updateNode = (node, skipLinkedNodes) => {
    setTimeout(() => {
      setProduct((prev) => {
        prev.template.nodes = prev.template.nodes.map((n) =>
          n.id === node.id ? cloneDeep(node) : n
        );
        return { ...prev };
      });
    }, 0);
    if (!skipLinkedNodes) {
      handleLinkedNodes(node.id, null, null, {
        grayscale: node.grayscale,
        fitImage: node.fitImage,
      });
    }
    if (node.type === 'image') {
      setSelectedNodeId(node.id);
    }
  };

  const onUpdateNodes = (nodes) => {
    setProduct((prev) => {
      prev.template.nodes = prev.template.nodes.map((n) => {
        const node = nodes.find((i) => i.id === n.id);
        if (node) {
          return cloneDeep(node);
        }
        return n;
      });
      return { ...prev };
    });
  };

  const onRotate = (val) => {
    if (!selectedNode) return;
    const imgNode = selectedNode;
    const imageElement = cropperRefs.current[selectedNodeId]?.current;
    if (!imageElement) return;
    const cropper = imageElement.cropper;
    cropper.rotate(val);
    console.log(`${val} degree rotation applied`);
    Sentry.captureMessage(`${val} degree rotation applied`);
    const imageData = cropper.getImageData();
    imgNode.rotate = imageData.rotate;
    updateNode(imgNode, true);
    if (val !== -1 && val !== 1) {
      setTimeout(() => {
        cropper.zoomTo(0);
      }, 0);
    }
    rotateLinkedNodes(selectedNodeId, val, imgNode.rotate);
  };

  const onBackgroundColorChange = (val) => {
    product.template.backgroundColor = val;
    Sentry.captureMessage('Background Color Changed');
    setProduct({
      ...product,
    });
  };

  const updateNodeImage = (node, file) => {
    node.image = file;
    node.rotate = undefined;
    return node;
  };

  const onImageSelect = useCallback(
    (file, nodeId) => {
      //setProductOptions(); //this was to clear options on image change
      //but it is already clearing on add to cart or change product so I think it is ok to remove this one
      //this is used when changing/loading from the image gallery
      if (!product) {
        alert('Please choose a product on the left first!');
        return;
      }
      const imgNode = nodeId
        ? product.template.nodes.find((n) => n.id === nodeId)
        : product.template.nodes.find((n) => n.type === 'image');

      const updatedNode = updateNodeImage(imgNode, file);

      setSelectedNodeId(imgNode.id);

      if (product.template.nodes.length === 1 && !product.template.overlay) {
        // apply any rotation to canvas first
        getRotationFromImage(file, updatedNode).then((rotate) => {
          if (rotate) {
            onCanvasRotate(rotate);
          }
          updateNode(updatedNode);
        });
      } else {
        updateNode(updatedNode);
      }
      handleLinkedNodes(imgNode.id, file);
      handleTextTemplate(imgNode.id, file);
    },
    [product]
  );

  const onFileSelect = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      onImageSelect(file, selectedNodeId);
    }
  };

  const rotateLinkedNodes = (changedNodeId, value, cropperRotation) => {
    const otherNodes = getSiblingNodes(changedNodeId, linkedNodes);
    if (otherNodes) {
      otherNodes.forEach((id) => {
        const node = product.template.nodes.find((n) => n.id === id);
        node.rotate = cropperRotation;
        const imageElement = cropperRefs.current[id]?.current;
        if (imageElement) {
          const cropper = imageElement.cropper;
          cropper.rotate(value);
          updateNode(node, true);
        }
      });
    }
  };

  const handleLinkedNodes = (nodeId, image, cropper, otherProps) => {
    const otherNodes = getSiblingNodes(nodeId, linkedNodes);
    if (otherNodes) {
      if (image) {
        otherNodes.forEach((id) => {
          const node = product.template.nodes.find((n) => n.id === id);
          updateNode(updateNodeImage(node, image), true);
        });
      }
      if (cropper) {
        const { zoomRatio } = product;
        const { crop, canvas } = getCropData(cropper, zoomRatio);
        otherNodes.forEach((id) => {
          const node = product.template.nodes.find((n) => n.id === id);
          const imageElement = cropperRefs.current[id]?.current;
          if (!node.image || !imageElement) return;
          const cropper = imageElement.cropper;
          if (!cropper.canvasData) return; // fixes Issue #186. Probably canvas not initialized yet
          cropper.setCanvasData({
            left: -crop.left * zoomRatio,
            top: -crop.top * zoomRatio,
            width: canvas.width * zoomRatio,
            height: canvas.height * zoomRatio,
          });
        });
      }

      if (otherProps) {
        otherNodes.forEach((id) => {
          const node = product.template.nodes.find((n) => n.id === id);
          Object.keys(otherProps).forEach((key) => {
            node[key] = otherProps[key];
            // reset crop and canvas if fitImage is changed because cropper do not update viewMode
            if (key === 'fitImage') {
              delete node.crop;
              delete node.canvas;
            }
          });
          updateNode(node, true);
        });
      }
    }
  };

  const handleTextTemplate = (nodeId, image) => {
    product.template.nodes.forEach((node) => {
      if (node.type === 'text' && node.defaultText?.includes(`{${nodeId}:`)) {
        const template = parseTextTemplate(node.defaultText);
        if (template) {
          let text = node.defaultText;
          template.forEach(({ variable, segments }) => {
            const [nid, prop] = segments;
            if (nodeId === nid) {
              // this would be when we're changing the image of the node itself
              const variables = getFileNameParts(image.name);
              text = text.replace(variable, variables[prop]);
            } else {
              const node = product.template.nodes.find((n) => n.id === nid);
              if (node?.image) {
                const variables = getFileNameParts(node.image.name);
                text = text.replace(variable, variables[prop]);
              }
            }
          });
          node.text = text;
          updateNode(node, true);
        } else {
          node.text = node.defaultText;
          updateNode(node, true);
        }
      }
    });
  };

  const onChangeFitImage = (e) => {
    const { checked } = e.target;
    if (!selectedNode) return;
    Sentry.captureMessage(`Fit image clicked, now set to ${checked}`);
    selectedNode.fitImage = checked;
    delete selectedNode.canvas;
    delete selectedNode.crop;
    updateNode(selectedNode);
  };

  const onCanvasRotate = () => {
    //should there be a delay here? no, not the problem area
    const newTemplate = applyCanvasRotation(product);
    setProduct(cloneDeep(newTemplate));
  };

  const getCropData = (imgNode, zoomRatio) => {
    let canvData;
    let imgData;
    if (imgNode.getCanvasData) {
      canvData = imgNode.getCanvasData();
      imgData = imgNode.getImageData();
    } else {
      const imageElement = cropperRefs.current[imgNode.id].current;
      const cropper = imageElement.cropper;
      canvData = cropper.getCanvasData();
      imgData = cropper.getImageData();
    }
    const crop = {
      top: Math.floor(Math.abs(canvData.top / zoomRatio)),
      left: Math.floor(Math.abs(canvData.left / zoomRatio)),
      //actual values are for back end, if fit is checked you need to know if the value is positive or negative
      //using ceil instead of floor to match rounding for neg and positive values? not sure this is correct but shouldn't be a problem
      topActual: Math.ceil(canvData.top / zoomRatio),
      leftActual: Math.ceil(canvData.left / zoomRatio),
    };
    const canvas = {
      width: Math.ceil(canvData.width / zoomRatio),
      height: Math.ceil(canvData.height / zoomRatio),
    };

    return {
      crop,
      canvas,
      rotate: imgData.rotate,
    };
  };

  const getData = (imgNode) => {
    if (imgNode.type !== 'image') return imgNode;
    const zoomRatio = getZoom(product);
    const node = { ...imgNode };
    if (imgNode.image) {
      const { crop, canvas, rotate } = getCropData(imgNode, zoomRatio);

      node.crop = crop;
      node.canvas = canvas;
      node.rotate = rotate;
      node.filename = node.image.name;
      node.fileHash = getFileHash(node.image);
      return node;
    }
    return imgNode;
  };

  const onAddToCart = (stayInEditor) => {
    const cartProduct = cloneDeep(product);
    const nodes = product.template.nodes.map(getData);
    cartProduct.template.nodes = nodes;
    if (itemId) {
      const item = cart.items.find((i) => i.id === itemId);
      if (!isImagesConfigured && !emptyNodesOk) {
        alert(
          "Slow down! Please wait for the image to load before clicking the arrow key. You will need to check the prior 5 or so images to make sure the cropping is correct (left arrow key loads the previous image) You might crash your browser if you don't slow down. If the crop box is empty click the left arrow on your keyboard a couple times and an image should load."
        );
        Sentry.captureMessage('Alert - slow down, clicking arrow key too fast');
        return;
      }

      const payload = {
        id: itemId,
        product: cartProduct,
        options: productOptions,
        quantity: quantity,
        category,
      };

      if (item.pkg) {
        payload.pkg = item.pkg;
      }
      dispatch(updateItem(payload));
      if (stayInEditor !== true) {
        enqueueSnackbar('Cart updated', {
          variant: 'success',
        });
        history.push('/cart');
      }
    } else {
      const payload = {
        id: shortId(),
        product: cartProduct,
        options: productOptions,
        quantity: quantity,
        category,
      };
      dispatch(addToCart(payload));
      dispatch(calculatePrice());
      enqueueSnackbar(`${product.size} added to cart`, {
        variant: 'success',
      });
      nodes.forEach((node) => {
        if (node.image) {
          dispatch(uploadItem(node.image));
        }
      });
      // check if catalog set to clear image on add to cart
      const group = catalogs.find((c) => c.cat_id === category);
      if (!group.preserve_on_add_to_cart) {
        resetEditor();
      } else {
        // catalog set to not clear image in editor
        // for units/packages so you can add the same image to a different product easily
        setProduct(cloneDeep(cartProduct));
      }
    }
    setQuantity(1);
    setProductOptions();
  };

  const onCropperReady = (nodeId, ref) => {
    cropperRefs.current[nodeId] = ref;
  };

  const onCrop = (nodeId, cropper) => {
    handleLinkedNodes(nodeId, null, cropper);
  };

  const resetEditor = () => {
    const _prod = group.products.find((i) => i.id === id);
    const newProduct = cloneDeep(_prod);
    assignNodeIds(newProduct.template.nodes);
    newProduct.zoomRatio = getZoom(newProduct);
    setProduct(newProduct);
  };

  const onCatalogChange = (e) => {
    const { value } = e.target;
    history.push(`/editor/${value}`);
  };

  const onOptionsChange = (values) => {
    setProductOptions(values);
    setShowOptions(false);
    if (itemId) {
      const item = cart.items.find((i) => i.id === itemId);
      item.options = values;
      dispatch(updateItem(item));
    }
  };

  const onToggleSettings = () => {
    setMobileOpen(!mobileOpen);
  };

  const onNodeClick = (id) => {
    setSelectedNodeId(id);
  };

  const cartItemCountLabel = useMemo(() => {
    if (itemId) {
      const idx = cart.items.findIndex((i) => i.id === itemId);
      return `(editing ${idx + 1}/${cart.items.length}) - `;
    }
    return null;
  }, [itemId, cart]);

  const group = catalogs.find((c) => c.cat_id === category);

  const nodeSettingsContent = product ? (
    <NodesSettings
      onImageSelect={onImageSelect}
      onUpdateNode={updateNode}
      onUpdateNodes={onUpdateNodes}
      product={product}
      onBackgroundColorChange={onBackgroundColorChange}
    />
  ) : null;

  const hasImageForSelectedNode = !!selectedNode?.image;

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className={classes.root}>
        <Helmet title={`${lab.lab_name} - ${catalog.cat_name}`}>
          <meta
            name="description"
            content="Choose a size, add an image and crop and zoom in as needed."
          />
        </Helmet>
        <Hidden smDown>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <Select
              variant="outlined"
              value={category}
              onChange={onCatalogChange}
              disabled={!!itemId}
            >
              {catalogs.map((i) => {
                if (!i.disable_cropper && !i.hidden) {
                  return (
                    <MenuItem value={i.cat_id} key={i.cat_id}>
                      {i.cat_name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            <Box className={classes.templatesList}>
              {itemId ? (
                <Typography variant="caption">
                  Product list disabled while editing from the cart. If you need
                  to change the size of an item in the cart please delete it
                  from the cart and order it again.
                  <br />
                  You also cannot add or change images while editing from the
                  cart.
                </Typography>
              ) : (
                group.products
                  .filter(isValidTemplate)
                  .map((i) => (
                    <ProductCard
                      key={i.id}
                      product={i}
                      isSelected={i.id === product?.id}
                      onSelect={onProductSelect}
                      showPrice={catalog.cat_show_prices}
                    />
                  ))
              )}
            </Box>
          </Drawer>
        </Hidden>
        <Box className={classes.content}>
          <Box align="left" className={classes.breadcrumb}>
            <Typography gutterBottom align="center" component="span">
              {cartItemCountLabel} {group.cat_name} Catalog
            </Typography>

            {product && (
              <Typography component="span" align="center">
                {' '}
                / {product.size}
              </Typography>
            )}
            {selectedNode?.image?.name && (
              <Typography
                component="span"
                align="center"
                className={highlight ? classes.highlight : ''}
              >
                {' '}
                / {selectedNode?.image?.name}
              </Typography>
            )}
          </Box>
          {product ? (
            <>
              {product.supersize && (
                <Hidden smUp>
                  <Box mb={2}>
                    <Alert severity="warning" variant="filled" elevation={6}>
                      <AlertTitle>Your Screen is Small...</AlertTitle>
                      This is a complicated template that would be much easier
                      to order on a different device with a larger screen.
                      Technically it should be possible to order with this
                      device but you really should try it on a larger screen. If
                      this is an older device you will likely have memory issues
                      as well.
                    </Alert>
                  </Box>
                </Hidden>
              )}
              {/* {renderDebugInfo()} */}
              <Canvas
                key={product.id}
                product={product}
                onUpdateNode={updateNode}
                onReady={onCropperReady}
                onNodeClick={onNodeClick}
                onImageSelect={onImageSelect}
                selectedNodeId={selectedNodeId}
                onCrop={onCrop}
                editing={itemId ? true : false}
              />
            </>
          ) : (
            <CatalogCard catalog={catalog} />
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {product && product.template && (
              <>
                <RotateSettings
                  disabled={!hasImageForSelectedNode}
                  onRotate={onRotate}
                  onReplaceImage={itemId ? null : onFileSelect}
                />
                <Box align="center" my={1} display="flex">
                  <Checkbox
                    disabled={!hasImageForSelectedNode}
                    color="primary"
                    label="Shrink to Fit (may add borders)"
                    title="This will shrink your image down so that the entire image fits in the box, with borders on the sides. Border color will be the background color."
                    checked={selectedNode?.fitImage || false}
                    onChange={onChangeFitImage}
                  />
                  {product.options && (
                    <Hidden smDown>
                      <Button
                        onClick={() => setShowOptions(true)}
                        variant="outlined"
                      >
                        Options
                      </Button>
                    </Hidden>
                  )}
                </Box>
              </>
            )}
            {product && (
              <CartOptions
                onToggleSettings={onToggleSettings}
                editing={itemId}
                quantity={quantity}
                onChange={setQuantity}
                onAdd={onAddToCart}
                hasItems={cart.items.length}
                hasImage={isImagesConfigured}
                emptyNodesOk={emptyNodesOk}
                category={category}
              />
            )}
          </Box>
        </Box>
        <Hidden smDown>
          <Drawer
            className={classes.settingsPane}
            variant="permanent"
            classes={{
              paper: classes.settingsPaper,
            }}
            anchor="right"
          >
            <Box padding={2}>
              <Box mt={1} mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!cart.items.length}
                  component={Link}
                  to="/cart"
                >
                  Checkout
                </Button>
              </Box>
              {nodeSettingsContent}
              {!itemId && (
                <ImagesGallery
                  disableClick={isMultiImageTemplate && !linkedNodes?.length}
                  selected={
                    isMultiImageTemplate
                      ? null
                      : product?.template.nodes.find((n) => n.type === 'image')
                          .image
                  }
                  onImageSelect={onImageSelect}
                  onAddToCart={onAddToCart}
                />
              )}
            </Box>
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            container={
              typeof document !== 'undefined' ? document.body : undefined
            }
            classes={{
              paper: classes.mobileSettingsPaper,
            }}
            anchor="right"
            variant="temporary"
            open={mobileOpen}
            onClose={onToggleSettings}
          >
            <Box>{nodeSettingsContent}</Box>
          </Drawer>
        </Hidden>
        {showOptions && (
          <OptionsModal
            item={{
              product: product,
              options: productOptions,
              quantity,
            }}
            currency={lab.globalOptions.currency}
            handleSubmit={onOptionsChange}
            onClose={() => setShowOptions(false)}
          />
        )}
      </Box>
    </DndProvider>
  );
};

export default Editor;
