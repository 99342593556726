import { grey, red, orange } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

export default (customization) => {
  // A custom theme for this app
  const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: customization?.primary_color || grey[600],
      },
      secondary: {
        main: customization?.secondary_color || orange[800],
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#fff',
      },
    },
    shape: 0,
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    typography: {
      h1: {
        fontSize: '1.6rem',
        fontWeight: 400,
        color: '#3a3a3a',
        paddingTop: '1rem',
        paddingBottom: '.5rem',
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 400,
        color: '#3a3a3a',
        paddingTop: '.2rem',
        paddingBottom: '.2rem',
      },
      h3: {
        fontSize: '1.4rem',
        fontWeight: 400,
        color: '#3a3a3a',
        paddingTop: '1rem',
        paddingBottom: '.7rem',
      },
      h4: {
        fontSize: '1.3rem',
        fontWeight: 500,
        color: '#3a3a3a',
        paddingTop: '1rem',
        paddingBottom: '.7rem',
      },
      h6: {
        fontSize: '1.1rem',
      },
    },
    overrides: {
      MuiPaper: {
        elevation1: {
          boxShadow: '0px 5px 20px rgba(158, 158, 158, 0.3)',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          borderRadius: 3,
        },
      },
      MuiLink: {
        underlineHover: {
          borderBottom: '1px dotted #909090',
          '&:hover': {
            textDecoration: 'none',
            borderBottom: '1px solid transparent',
          },
        },
      },
      MuiTypography: {
        paragraph: {
          marginTop: '1.3rem',
        },
      },
    },
  });

  return theme;
};
