import { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inchToPixels } from 'utils/index';
import { TextNode as TextNodeSettings } from './TextSettings';
import { clone } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    left: ({ node, zoomRatio }) => inchToPixels((node.x - 0.15) * zoomRatio),
    top: ({ node, zoomRatio }) => inchToPixels((node.y - 0.15) * zoomRatio),
    width: ({ node, zoomRatio }) =>
      inchToPixels((node.width + 0.3) * zoomRatio),
    height: ({ node, zoomRatio }) =>
      inchToPixels((node.height + 0.3) * zoomRatio),
    textAlign: ({ node }) => node.align,
    position: 'absolute',
    zIndex: 2,
    cursor: 'pointer',
    '&:hover': {
      outline: '1px solid lightblue',
      outlineOffset: '-10px',
    },
    transform: ({ node }) => (node.rotate ? 'rotate(-25deg)' : 'none'),
    transformOrigin: 'center center',
  },
  text: ({ node, zoomRatio }) => ({
    fontSize: node.fontSize * zoomRatio,
    color: node.fontColor,
    fontWeight: node.fontWeight,
    fontFamily: node.fontFamily,
  }),
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

const TextNode = ({ node, zoomRatio, onChange }) => {
  const classes = useStyles({ node, zoomRatio });
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    width,
    height,
    fontFamily = 'Open Sans',
    fontSize,
    fontColor,
    fontWeight = 'normal',
    rotate,
    dropShadow,
    enableShadow,
    align,
    fontStyle = 'normal',
  } = node;
  let text = node.text || 'Click to add text';
  if (node.text === '%year%') {
    const date = new Date();
    const year = date.getFullYear();
    text = String(year);
    node.text = text;
  }

  const widthPx = Math.round(inchToPixels(width + 0.3));
  const heightPx = Math.round(inchToPixels(height + 0.3));

  let textX = `${45 * zoomRatio}px`;
  let textAnchor = 'start';
  if (align === 'center') {
    textAnchor = 'middle';
    textX = '50%';
  } else if (align === 'right') {
    textAnchor = 'end';
    textX = `${((widthPx - 45) / widthPx) * 100}%`;
  }
  // Remove hardCodeRotation variable and its usage

  const fontSizeZoomed = fontSize * zoomRatio;
  const open = Boolean(anchorEl);

  const onTextClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.root}
      onClick={onTextClick}
    >
      <svg viewBox={`0 0 ${widthPx * zoomRatio} ${heightPx * zoomRatio} `}>
        {enableShadow ? (
          <g
            overflow="hidden"
            textAnchor={textAnchor}
            fontSize={fontSizeZoomed}
            fontWeight={fontWeight}
            fontStyle={fontStyle}
            fontFamily={fontFamily}
          >
            <defs>
              <filter id="shadow" x="0%" y="0%" width="200%" height="200%">
                <feGaussianBlur
                  stdDeviation={`${dropShadow} ${dropShadow}`}
                  result="shadow"
                />
                <feOffset dx="1" dy="1" />
              </filter>
            </defs>
            <text
              filter="url(#shadow)"
              fill="black"
              x={`${textX}`}
              y="50%"
              dy={`${fontSizeZoomed / 3}px`}
            >
              {text}
            </text>
            <text
              x={textX}
              y="50%"
              dy={`${fontSizeZoomed / 3}px`}
              fill={`${fontColor}`}
            >
              {text}
            </text>
          </g>
        ) : (
          <text
            x={`${textX}`}
            y="50%"
            dy={`${fontSizeZoomed / 3}px`}
            textAnchor={textAnchor}
            fontWeight={fontWeight}
            fontSize={fontSizeZoomed}
            fontFamily={fontFamily}
            fontStyle={fontStyle}
            fill={fontColor}
            fillOpacity={node.text ? '1' : '0.5'}
          >
            {text}
          </text>
        )}
      </svg>
      {open && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Box p={2}>
            <IconButton
              size="small"
              onClick={handleClose}
              className={classes.close}
            >
              <CloseIcon />
            </IconButton>
            <TextNodeSettings node={node} onChange={onChange} />
          </Box>
        </Popover>
      )}
    </Box>
  );
};

export default TextNode;
