import React, {
  createContext,
  useState,
  useCallback,
  useRef,
} from 'react';

const uuid = () => (Math.random() * Math.random()).toString(36).substr(2);

export const AlertContext = createContext(null);

export const TYPES = {
  PROMPT: 'prompt',
  CONFIRM: 'confirm',
};

const DEFAULT_OPTIONS = {
  type: TYPES.CONFIRM,
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
}

const DEFAULT_PROMPT_OPTIONS = {
  ...DEFAULT_OPTIONS,
  type: TYPES.PROMPT,
  inputValue: '',
  confirmationText: 'Submit',
  onConfirm: (v) => v,
}

const buildOptions = (defaultOptions, options) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {}),
  };
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {}),
  };
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {}),
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
  }
};

export const AlertProvider = ({ children, component: AlertComponent }) => {
  const alertContext = useRef(null);
  const [alerts, setAlerts] = useState([]);

  const remove = (id) => {
    if (!id) {
      id = alerts[alerts.length - 1].id;
    }
    setAlerts((prev) => prev.filter((i) => i.id !== id));
  };

  const removeAll = () => setAlerts([]);

  const show = useCallback((options) => {
    if (typeof options ==="string") {
      options = {...DEFAULT_OPTIONS};
      options.title = options;
    } else if (!options.type) {
      options = Object.assign({}, { ...DEFAULT_OPTIONS }, options);
    }

    const alert = {
      id: uuid(),
      options,
    };

    alert.close = () => remove(alert.id);

    setAlerts((prev) => prev.concat(alert));
    return alert;
  }, []);

  const confirm = useCallback((options) => {
    const alertOptions = { ...DEFAULT_OPTIONS };
    if (typeof options === "string") {
      alertOptions.title = options
    } else {
      Object.assign(alertOptions, options);
    }

    return new Promise((resolve, reject) => {
      alertOptions.onConfirm = options.onConfirm || resolve;
      alertOptions.onCancel = () => reject();
      return show(alertOptions);
    });
  }, []);

  const prompt = useCallback((options) => {
    const alertOptions = { ...DEFAULT_PROMPT_OPTIONS };
    if (typeof options === "string") {
      alertOptions.title = options
    } else {
      Object.assign(alertOptions, options);
    }

    return show(alertOptions);
  }, []);

  alertContext.current = {
    show,
    confirm,
    prompt,
    remove,
    removeAll,
  };

  return (
    <AlertContext.Provider value={alertContext.current}>
      {children}
      {alerts.map((alert) => (
        <AlertComponent key={alert.id} {...alert} />
      ))}
    </AlertContext.Provider>
  );
};
