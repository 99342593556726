import React from 'react';
import PropTypes from 'prop-types';
import Switch from '../Radio';

export default function FormikRadioInput({ form, name, ...props }) {
  return (
    <Switch
      checked={props.value}
      {...props}
      onChange={(e) => {
        form.setFieldValue(name, e.target.checked, true);
      }}
    />
  );
}

FormikRadioInput.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
};
