import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';

export default function NotFound() {
  return (
    <Container>
      <Box mt={8}>
        <Typography variant="body2">404 Error</Typography>
        <Typography variant="h1">Page Not Found</Typography>
        <Typography variant="body2">
          We're sorry but this page does not seem to exist
        </Typography>
      </Box>
    </Container>
  );
}
