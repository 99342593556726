import each from 'lodash/each';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';
import groupBy from 'lodash/groupBy';

// export function getTaxRate(lab, shippingAddress, shipmethodId) {
//   // shippinAddress is either the address from the customer or the shippingAddress for dropship
//   const rate = lab.globalOptions.sales_tax;
//   const homeOnly = lab.globalOptions.state_tax_only;
//   const labCountry = lab.lab_country;
//   const labState = lab.lab_state;
//   const shippingType = find(lab.globalOptions.shipping, {
//     id: shipmethodId,
//   });

//   if (homeOnly) {
//     if (
//       (shippingAddress?.country === labCountry &&
//         shippingAddress?.state === labState) ||
//       shippingType.shiptype === 'pickup'
//     ) {
//       return rate;
//     } else {
//       return 0;
//     }
//   } else return rate;
// }

function getAggregateByPriceId(priceId, lineItems, productsMap) {
  return reduce(
    lineItems,
    (memo, item) => {
      const product = productsMap[item.productId];
      if (
        product.ordertier &&
        product.priceId === priceId &&
        product.aggregateByPrice
      ) {
        const count = memo + item.quantity;
        return count;
      }
      return memo;
    },
    0
  );

  const priceAggrgate = {};

  lineItems.forEach((item) => {
    const product = productsMap[item.productId];
    if (product.ordertier && product.priceId && product.aggregateByPrice) {
      const aggregate = (priceAggrgate[product.priceId] =
        priceAggrgate[product.priceId] || 0);
    }
  });
}

export function getTirePrice(price, count) {
  let slabPrice = 0;

  if (isObject(price)) {
    let slab = 0;
    Object.keys(price).forEach((i) => {
      const parsedNumber = Number(i);
      if (count >= parsedNumber && parsedNumber > Number(slab)) slab = i;
    });
    slabPrice = price[slab];
  } else {
    slabPrice = price;
  }
  return slabPrice;
}

/*
Calculate price of given product, quantity and options
if its a ordertier product, also pass total aggregated query
 */
export function calculateItemPriceAndWeight(
  product,
  productPrice,
  quantity,
  options,
  totalQuantity
) {
  const { ordertier, weight } = product;

  let totalPrice = 0;
  let totalWeight = 0;
  let slabPrice = 0;
  let optionsRate = 0;
  const slabCount = ordertier ? totalQuantity : quantity;

  if (isObject(productPrice)) {
    let slab = 0;
    Object.keys(productPrice).forEach((i) => {
      i = Number(i);
      if (slabCount >= i && i > slab) slab = i;
    });
    slabPrice = productPrice[slab];
    totalPrice = quantity * slabPrice;
  } else {
    slabPrice = productPrice;
    totalPrice = quantity * productPrice;
  }

  totalWeight = weight * quantity;

  //now find options price if selected.
  each(options, (selected, cat) => {
    console.log(`options`, options);
    const option = find(product.options, { id: cat });
    console.log(`option`, option);
    const choice = find(option.choices, { id: selected });

    const tirePrice = getTirePrice(choice.price, quantity);
    const isOrderTire = option.ordertier;
    const oRate = isOrderTire ? tirePrice : tirePrice * quantity;
    optionsRate = optionsRate + oRate;
    totalPrice = totalPrice + oRate;
    totalWeight = totalWeight + choice.weight * quantity;
  });

  return { totalPrice, totalWeight, rate: slabPrice, optionsRate };
}

//calculate each line item total
export function calculateCartPrice(lineItems, productsMap, priceList) {
  const grouped = groupBy(lineItems, 'productId');
  const batch = {};
  //loop through each product type and calculate line item price
  each(grouped, (items, productId) => {
    const product = productsMap[productId];

    if (!product) throw new Error(`Product ${productId} not found.`);

    const { ordertier, priceId, aggregateByPrice } = product;

    const price = priceId
      ? priceList.find((i) => i.id === priceId).price
      : product.price;

    let totalCount = 0;

    /*
     *   if not order tier then don't need to calculate total count of same product.
     *   Price will be determined based to line item quantity, weight and options
     */

    if (!ordertier) {
      items.forEach((line) => {
        const { totalPrice, totalWeight, rate, optionsRate } =
          calculateItemPriceAndWeight(
            product,
            price,
            line.quantity,
            line.options
          );
        batch[line.id] = {
          totalPrice,
          totalWeight,
          rate,
          optionsRate,
        };
      });
    } else {
      /*
       * this is order tier product. We need to get total count of same product id across cart.
       * afterwards apply slab price
       */

      totalCount = getTotalCountOfProductById(productId, items, productsMap);

      //now, find line item price
      items.forEach((line) => {
        let aggregate = totalCount;
        if (priceId && aggregateByPrice) {
          aggregate = getAggregateByPriceId(priceId, lineItems, productsMap);
        }
        const { totalPrice, totalWeight, rate, optionsRate } =
          calculateItemPriceAndWeight(
            product,
            price,
            line.quantity,
            line.options,
            aggregate
          );
        batch[line.id] = {
          productId,
          totalPrice,
          totalWeight,
          rate,
          optionsRate,
        };
      });
    }
  });
  return batch;
}

export function getTotalCountOfProductById(productId, lineItems) {
  return reduce(
    lineItems,
    (memo, item) => {
      if (item.productId === productId) {
        memo = memo + Number(item.quantity);
      }
      return memo;
    },
    0
  );
}

function getTotalWeight(lineItems) {
  return reduce(
    Object.values(lineItems),
    (memo, line) => {
      return memo + line.totalWeight;
    },
    0
  );
}

function calculateShippingTax(shippingTotal, shipmethodId, labData, sales_tax) {
  const { shipping } = labData.globalOptions;
  const shippingMethod = find(shipping, { id: shipmethodId });

  if (!shippingMethod.taxable) return 0;

  return shippingTotal * sales_tax;
}

export function calculateGlobalOptionsTotal(
  options,
  lineItemsTotal,
  global_checkout_options,
  imagesCount
) {
  let total = 0;

  each(options, (val, key) => {
    const option = find(global_checkout_options, { id: key });
    const choice = find(option.choices, { id: val });
    if (choice.price) {
      if (choice.priceperfile) {
        total = total + choice.price * imagesCount;
      } else {
        total = total + choice.price;
      }
      if (choice.pcontotal) {
        total = total + lineItemsTotal * (choice.pcontotal / 100);
      }
    }
  });
  return +total.toFixed(2);
}

//total tax on global options
function calculateGlobalOptionsTax(
  lineItemsTotal,
  orderOptions,
  labData,
  sales_tax,
  imagesCount
) {
  const { global_checkout_options } = labData.globalOptions;
  let tax = 0;

  each(orderOptions, (val, key) => {
    const option = find(global_checkout_options, { id: key });

    const choice = find(option.choices, { id: val });
    let total = 0;

    if (choice.taxable && choice.price) {
      if (choice.priceperfile) {
        total = total + choice.price * imagesCount;
      } else {
        total = total + choice.price;
      }
      if (choice.pcontotal) {
        total = total + lineItemsTotal * (choice.pcontotal / 100);
      }
      tax = tax + total * sales_tax;
    }
  });

  return tax;
}

/*
 Tax amount is calculated on item total + global options (if tax applicable) + shipping (if applicable)
 */
export function getTotalTax(cart, lineItemsTotal, shippingTotal, labData) {
  console.log('cart - inside gettotaltax ++++++++++++++++++++++++++++++++++');
  //const rate = cart.taxRate;
  // if (rate === 0) return 0;
  const totalLineItemsTax = lineItemsTotal * cart.taxRate;
  const globalOptionsTax = calculateGlobalOptionsTax(
    lineItemsTotal,
    cart.orderOptions,
    labData,
    cart.taxRate,
    cart.imagesCount
  );
  const shippingTax = calculateShippingTax(
    shippingTotal,
    cart.shipmethodId,
    labData,
    cart.taxRate
  );

  return totalLineItemsTax + globalOptionsTax + shippingTax;
}

//Calculate shipping on cart value and weight
export function getShippingTotal(
  shipmethodId,
  lineItems,
  lineItemsTotal,
  optionsTotal,
  shippingOptions
) {
  const shippingMethod = find(shippingOptions, { id: shipmethodId });

  if (!shippingMethod) {
    throw new Error(`Shipping method id ${shipmethodId} not found.`);
  }

  const { price, tieruses } = shippingMethod;

  switch (tieruses) {
    case 'ordertotal':
      const orderTotal = lineItemsTotal + optionsTotal;
      return getTirePrice(price, orderTotal);
    case 'weight':
      return getTirePrice(price, getTotalWeight(lineItems));
    case 'flatrate':
      return price;
  }
}
