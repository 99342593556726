import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Box, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    margin: 'auto',
  },
  cardMedia: {
    height: 150,
  },
  box: {
    flexGrow: 'inherit',
    marginTop: 30,
  },
}));

const CatalogCard = ({ catalog }) => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.cardMedia}
          image={catalog.cat_icon}
          title={catalog.cat_name}
        />
        <CardContent>
          <Typography gutterBottom variant="h2" component="div">
            {catalog.cat_name}
          </Typography>
          <Typography paragraph variant="body1">
            {catalog.cat_description}
          </Typography>
          <Divider />
          <Typography paragraph variant="body2">
            Choose a size from the left pane to get started.
          </Typography>
          <Typography paragraph variant="body2">
            You can add images into the right pane or directly into a blank
            template.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CatalogCard;
