import React, { useMemo, useState, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Button from 'components/Button';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/react';
import { useDropzone } from 'react-dropzone';

const PackageCard = ({
  pkg,
  catalog,
  onPackageSelect,
  onDelete,
  onEdit,
  onFilesSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const items = pkg.items.map((i) => {
    const p = catalog.products.find((p) => p.id === i.productId);
    return {
      ...i,
      product: p,
    };
  });

  const hasDeletedProduct = useMemo(() => {
    return items.some((i) => !i.product);
  }, [items]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        onFilesSelect(acceptedFiles, pkg, catalog);
      }
    },
    [pkg]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    multiple: true,
    useFsAccessApi: false,
    disabled: hasDeletedProduct,
    onDrop,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    Sentry.captureMessage('Deleting Package');
    handleClose();
    onDelete(pkg);
  };

  const handleEdit = () => {
    handleClose();
    onEdit(pkg);
  };

  return (
    <Card
      {...getRootProps({
        style: {
          border: isDragActive ? '2px dashed #000' : '1px solid #ccc',
        },
      })}
    >
      <CardHeader
        action={
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={pkg.packageName}
        titleTypographyProps={{
          variant: 'h6',
          style: { fontWeight: 500 },
        }}
      />
      <CardContent style={{ minHeight: '130px' }}>
        {items.map((i, idx) => (
          <Typography key={idx}>
            {i.product ? i.product.size : 'Product removed'} - {i.quantity}
          </Typography>
        ))}
        {hasDeletedProduct && (
          <Typography color="error">
            Some products have been removed from the catalog. Please update your
            package.
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Typography variant="body2">Drag and Drop files - or -</Typography>
        <Button
          color="primary"
          variant="outlined"
          disabled={hasDeletedProduct}
          onClick={() => onPackageSelect(pkg)}
        >
          Select files
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <Divider />
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </CardActions>
    </Card>
  );
};

export default PackageCard;
