const handleClipboard = cb => {
  if (navigator.clipboard && navigator.clipboard.readText) {
    navigator.clipboard
      .readText()
      .then(text => {
        cb(text);
      })
      .catch(err => {
        alert(err.message);
        cb();
      });
  } else {
    const text = prompt("Paste your address");
    if (text) {
      cb(text);
    } else {
      cb();
    }
  }
};

export default handleClipboard;