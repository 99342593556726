import React from 'react';
import PropTypes from 'prop-types';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function Checkbox({
  label,
  labelStyle = {},
  value,
  helpText,
  hasError,
  wrapperStyle = {},
  title,
  ...props
}) {
  return (
    <div style={{ ...wrapperStyle }}>
      <FormControlLabel
        title={title}
        control={
          <MuiCheckbox
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 16 }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: 16 }} />}
            checked={value}
            {...props}
          />
        }
        label={
          <Typography
            style={{
              marginTop: 2,
              fontSize: 14,
              fontWeight: 500,
              ...labelStyle,
            }}
          >
            {label}
          </Typography>
        }
      />
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  wrapperStyle: PropTypes.object,
};
